import React from 'react'
import { AccountDto, BusinessUnit, DeactivatedAccount } from '../../services/Dtos'
import {
  NavLink,
  Outlet,
  useFetcher,
  useLoaderData,
  useNavigate,
  useOutletContext,
  useParams,
  useRouteError,
  useRouteLoaderData,
  useSearchParams
} from 'react-router-dom'
import { CardDialog } from '../../components/CardDialog'

export const DeactivatedAccountsPage = () => {
  const { accounts, accountsWithInvoicedLicenses } = useLoaderData() as {
    accounts: AccountDto[]
    accountsWithInvoicedLicenses: DeactivatedAccount[]
  }
  const allBusinessUnits = useRouteLoaderData('withBusinessUnits') as BusinessUnit[]
  const navigate = useNavigate()
  let [searchParams] = useSearchParams()
  const reactivated = searchParams.get('reactivated')
  const deleted = searchParams.get('deleted')

  return (
    <React.Fragment>
      <h1 className="text-xl ml-md mb-md">Deactivated Accounts</h1>
      {reactivated && <div className="alert alert-success">Account {reactivated} was reactivated</div>}
      {deleted && <div className="alert alert-success">Account {deleted} was deleted</div>}

      <div className="card">
        <table className="table">
          <thead>
            <tr>
              <th>Account Name</th>
              <th>External ID</th>
              <th>Description</th>
              <th>Business Unit</th>
              <th>Creator</th>
              <th>Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map(account => {
              const { id, businessUnitId, externalId, description, authorName, name, created } = account
              const bu = allBusinessUnits.find(b => b.id === businessUnitId)

              return (
                <tr key={id}>
                  <td>
                    <NavLink to={`/accounts/${id}`} className="text-blue font-bold">
                      {name}
                    </NavLink>
                  </td>
                  <td>{externalId}</td>
                  <td>{description}</td>
                  <td>
                    <NavLink to={`/businessunits/${businessUnitId}`} className="text-blue font-bold">
                      {businessUnitId} {bu?.name}
                    </NavLink>
                  </td>
                  <td>{authorName}</td>
                  <td>{created ? new Date(created).toLocaleDateString() : '-'}</td>
                  <td>
                    <div className="flex justify-end space-x-xs">
                      <button
                        aria-label={`Reactivate ${name}`}
                        type="button"
                        className="btn shrink-0"
                        onClick={() => navigate(`${account.id}/reactivate`)}
                      >
                        Reactivate
                      </button>

                      <button
                        aria-label={`Delete ${name}`}
                        type="button"
                        className="btn btn-primary-danger shrink-0"
                        onClick={() => navigate(`${account.id}/delete`)}
                        disabled={accountsWithInvoicedLicenses?.some(e => e.accountId === account.id)}
                      >
                        Delete forever
                      </button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <Outlet context={{ accounts }} />
    </React.Fragment>
  )
}

export function DeleteDeactivatedAccountDialog() {
  const fetcher = useFetcher()
  const navigate = useNavigate()
  const { accounts } = useOutletContext<{ accounts: AccountDto[] }>()
  const error = useRouteError() as { message: string }
  const { accountId } = useParams()
  const account = accounts.find(a => a.id === accountId)
  if (!account) {
    return <h1>Couldn't find account with id {accountId}</h1>
  }
  return (
    <CardDialog onClose={() => navigate('../')}>
      <h1>Delete '{account.name}'?</h1>
      {error && <div className="alert alert-danger">Error: {error.message}</div>}
      <p>
        This will delete the account, the user access and licenses for this account. Are you sure you want to continue?
      </p>
      <div
        className="grid-cols-6 gap-x-md mt-lg inline-flex mb-md"
        style={{ float: 'right' }}
        data-testid="confirm-delete-dialog"
      >
        <button
          type="button"
          className="btn w-10"
          onClick={() => {
            navigate('../')
          }}
        >
          No
        </button>
        <fetcher.Form method="delete">
          <button className="btn btn-primary-dark w-10" disabled={fetcher.state !== 'idle'}>
            {fetcher.state !== 'idle' ? <div className="loadingSpinner" /> : 'Delete'}
          </button>
        </fetcher.Form>
      </div>
    </CardDialog>
  )
}

export function ReactivateAccountDialog() {
  const fetcher = useFetcher()
  const navigate = useNavigate()
  const { accounts } = useOutletContext<{ accounts: AccountDto[] }>()
  const { accountId } = useParams()
  const error = useRouteError() as { message: string }
  const account = accounts.find(a => a.id === accountId)
  if (!account) {
    return <h1>Couldn't find account with id {accountId}</h1>
  }
  return (
    <CardDialog onClose={() => navigate('../')}>
      <h1 className="text-xl">
        Are you sure you want to reactivate
        <br />
        {account.name}?
      </h1>
      {error && <div className="alert alert-danger">Error: {error.message}</div>}

      <div className="grid grid-cols-6 gap-x-md mt-lg" data-testid="confirm-reactivate-dialog">
        <button
          type="button"
          className="btn col-span-2 col-start-3"
          disabled={fetcher.state !== 'idle'}
          onClick={() => navigate('../')}
        >
          No
        </button>
        <fetcher.Form method="put" action=".">
          <button
            name="accountState"
            value="ACTIVE"
            className="btn btn-primary-dark col-span-2 flex items-center justify-center"
            disabled={fetcher.state !== 'idle'}
          >
            {fetcher.state !== 'idle' ? <div className="loadingSpinner" /> : 'Reactivate'}
          </button>
        </fetcher.Form>
      </div>
    </CardDialog>
  )
}
