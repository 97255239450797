import React, { Dispatch, SetStateAction, useState } from 'react'
import { LicenseService } from '../../../services/LicenseService'

type Props = {
  licenseId: string
  onComplete: () => void
}

const FIVE_SECONDS = 5000

function showAlertForFiveSeconds(message: string, setAlert: Dispatch<SetStateAction<string | undefined>>) {
  setAlert(message)
  setTimeout(() => {
    setAlert(undefined)
  }, FIVE_SECONDS)
}

type AliasState = 'not-checked' | 'not-available' | 'available'

export const AddAliasForm = ({ licenseId, onComplete }: Props) => {
  const [alias, setAlias] = useState('')
  const [alert, setAlert] = useState<string | undefined>()
  const [aliasState, setAliasState] = useState<AliasState>('not-checked')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onChange = (value: string) => {
    if (value.match(/^[a-zA-Z0-9-]+$/) && value !== alias) {
      setAliasState('not-checked')
      setAlias(value)
    }
  }

  const onClick = () => {
    if (aliasState === 'available' && !isSubmitting) {
      setIsSubmitting(true)
      LicenseService.createAlias(alias, licenseId)
        .then(() => {
          setIsSubmitting(false)
          onComplete()
        })
        .catch(() => {
          showAlertForFiveSeconds('Oh snap! Something went wrong. Please try again later.', setAlert)
        })
    } else if (aliasState === 'not-checked') {
      setIsSubmitting(true)
      LicenseService.isAliasAvailable(alias)
        .then(result => {
          result ? setAliasState('available') : setAliasState('not-available')
          setIsSubmitting(false)
        })
        .catch(() => {
          showAlertForFiveSeconds('Oh snap! Something went wrong. Please try again later.', setAlert)
        })
    }
  }

  return (
    <div className="p-lg flex flex-col gap-md">
      {alert && <div className="alert alert-danger centerAbsolute">{alert}</div>}

      <div>
        <label htmlFor="alias">Create Alias</label>
        <input
          id="alias"
          type="text"
          className=" mt-sm"
          value={alias}
          onChange={({ target }) => onChange(target.value)}
        />
      </div>

      {aliasState === 'not-available' && <div className="text-red">Alias is not available</div>}

      {aliasState === 'available' ? (
        <button className="btn btn-success self-end" disabled={isSubmitting || !alias} onClick={onClick}>
          {isSubmitting && <div className="loadingSpinner" />}
          Create alias
        </button>
      ) : (
        <button className="btn btn-primary-dark self-end" disabled={isSubmitting || !alias} onClick={onClick}>
          {isSubmitting && <div className="loadingSpinner" />}
          Check alias
        </button>
      )}
    </div>
  )
}
