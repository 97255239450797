import jwtDecode from 'jwt-decode'
import { authentication } from './authentication'
import { logError } from './logError'

export function userIsAdmin(): boolean {
  try {
    const token = authentication.getToken()
    return (jwtDecode(token) as any).full_resource_access.includes('ACCOUNT_ADMIN')
  } catch (error: any) {
    logError(new Error('Could not extract .full_resource_access from invalid JWT'), error)
    return false
  }
}
export function userIsMarketAdmin(): boolean {
  try {
    const token = authentication.getToken()
    return (
      (jwtDecode(token) as any).scoped_resource_access?.filter(
        (resource: string) => resource.includes('AccountManager') && resource.includes('MARKET_ADMIN')
      ).length > 0
    )
  } catch (error: any) {
    logError(new Error('Could not extract .scoped_resource_access from invalid JWT'), error)
    return false
  }
}
