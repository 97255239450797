import React, { useState, ReactNode, ReactNodeArray } from 'react'
import { MenuIcon } from './icons/MenuIcon'
import styled from 'styled-components'
import { colors, spacing } from '../styles'

type Props = {
  children: ReactNode | ReactNodeArray
  id: string
}

export const HoverMenu = ({ id, children }: Props) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      aria-label="License actions"
      data-testid={id}
      style={{
        width: '30px',
        height: '30px',
        border: '1px solid rgba(117, 117, 117, 0.2)',
        borderRadius: '14px',
        padding: 2
      }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <MenuIcon open={open} />
      {open ? (
        <div style={{ display: 'relative', padding: '2px' }}>
          <HoverContent>{children}</HoverContent>
        </div>
      ) : null}
    </div>
  )
}

const HoverContent = styled.div`
  position: relative;
  background-color: white;
  top: -80px;
  left: -200px;
  width: 200px;
  box-shadow: 2px 5px 10px #888888;
  z-index: 1;
`

type MenuButtonProps = {
  icon?: ReactNode
  text: string
  disabledTitle?: string
  onClick: () => void
}

export const MenuButton = ({ icon, text, onClick, disabledTitle }: MenuButtonProps) => {
  return disabledTitle ? (
    <DisabledButton title={disabledTitle}>
      {icon}
      <span style={{ marginLeft: spacing.sm }}>{text}</span>
    </DisabledButton>
  ) : (
    <Button onClick={onClick}>
      {icon}
      <span style={{ marginLeft: spacing.sm }}>{text}</span>
    </Button>
  )
}

const Button = styled.button`
  display: block;
  width: 100%;
  color: ${colors.SECONDARY_TEXT};
  background-color: #fff;
  border: 1px solid ${colors.GREY};
  min-height: 24px;
  padding: ${spacing.sm};
  cursor: pointer;
  text-align: left;
  font-weight: bolder;
  font-size: small;
  :focus {
    outline: 0;
  }
`

const DisabledButton = styled(Button)`
  opacity: 0.3;
  cursor: not-allowed;
`
