import React from 'react'
import { isUnknownEntityNode, PathScope, ResourceType, UnknownResourceType } from '../../../services/Dtos'

// eslint-disable-next-line no-unused-vars
const typeToDisplayMap: { [key in ResourceType | UnknownResourceType]: string } = {
  clearingHouse: 'Clearing Houses',
  collectionType: 'Collection Types',
  machineType: 'Machine Types',
  region: 'Regions',
  serialNumber: 'Serial numbers',
  state: 'States',
  storeReference: 'Stores',
  locationId: 'Stores',
  chainId: 'Organizations',
  subChainId: 'Sub Organizations',
  country: 'Countries',
  installationId: 'Machines',
  UNKNOWN_clearingHouse: 'Unknown Clearing Houses',
  UNKNOWN_collectionType: 'Unknown Collection Types',
  UNKNOWN_machineType: 'Unknown Machine Types',
  UNKNOWN_region: 'Unknown Regions',
  UNKNOWN_serialNumber: 'Inactive Serial numbers',
  UNKNOWN_state: 'Unknown States',
  UNKNOWN_storeReference: 'Inactive Stores',
  UNKNOWN_locationId: 'Inactive Stores',
  UNKNOWN_chainId: 'Inactive Organizations',
  UNKNOWN_subChainId: 'Inactive Sub Organizations',
  UNKNOWN_country: 'Unknown Countries',
  UNKNOWN_installationId: 'Inactive Machines'
}

type Props = {
  scopes: PathScope[]
}

type IncludesExcludesSummary = Map<ResourceType | UnknownResourceType, number>
type MiniScopeSummary = {
  includes: IncludesExcludesSummary
  excludes: IncludesExcludesSummary
}

export function createScopesMiniSummary(scopes: PathScope[]): MiniScopeSummary {
  return scopes.reduce(
    (summary: MiniScopeSummary, scope: PathScope) => {
      scope.include.forEach(incl => {
        summary.includes.set(incl.type, (summary.includes.get(incl.type) ?? 0) + 1)
      })
      scope.exclude.forEach(excl => {
        summary.excludes.set(excl.type, (summary.excludes.get(excl.type) ?? 0) + 1)
      })
      return summary
    },
    { includes: new Map(), excludes: new Map() } as MiniScopeSummary
  )
}

export const ScopesMiniSummary = ({ scopes }: Props) => {
  const unknownEntities = scopes.flatMap(value =>
    value.include.filter(isUnknownEntityNode).concat(value.exclude.filter(isUnknownEntityNode))
  )
  const validScopes = scopes
    .map(scope => {
      return {
        include: scope.include.filter(n => !isUnknownEntityNode(n)),
        exclude: scope.exclude.filter(n => !isUnknownEntityNode(n))
      }
    })
    .filter(scope => scope.include.length || scope.exclude.length)
  const summary = createScopesMiniSummary(validScopes)
  return (
    <div>
      <ul>
        <li>
          <span className="font-bold">
            {validScopes.length > 0 ? (
              'Allowing:'
            ) : unknownEntities.length > 0 ? (
              <b>⚠️Only inactive installations are configured for this account</b>
            ) : (
              <b>No installations are configured for this account</b>
            )}
          </span>
          <span className="ml-sm text-green">
            {Array.from(summary.includes.entries()).reduce((acc, incl) => {
              const prefix = acc ? `${acc}, ` : ''
              return `${prefix}${typeToDisplayMap[incl[0]]}: ${incl[1]}`
            }, '')}
          </span>
        </li>
        <li>
          {summary.excludes.size > 0 && <span className="font-bold">Except:</span>}
          <span className="ml-sm">
            {Array.from(summary.excludes.entries()).reduce((acc, incl) => {
              const prefix = acc ? `${acc}, ` : ''
              return `${prefix}${typeToDisplayMap[incl[0]]}: ${incl[1]}`
            }, '')}
          </span>
        </li>
        {unknownEntities.length > 0 && validScopes.length > 0 && (
          <li className="text-red">⚠️Some inactive installations are configured for this account</li>
        )}
      </ul>
    </div>
  )
}
