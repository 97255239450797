import React from 'react'
import { Dialog } from './Dialog'
import { CloseIcon } from './icons'

type Props = {
  onClose: () => void
  children?: React.ReactNode
}

export const CardDialog = ({ onClose, children }: Props) => {
  return (
    <Dialog onClose={onClose} shouldCloseOnOverlayClick={false}>
      <div className="card" style={{ minWidth: '30vw', maxHeight: '100%', overflowY: 'auto' }}>
        <div className="pl-lg pr-lg pb-lg pt-md ">
          {onClose && (
            <button className="btn btn-icon top-lg right-lg ml-auto" onClick={onClose}>
              <CloseIcon color={'var(--colors-blue)'} size="25" />
            </button>
          )}
          {children}
        </div>
      </div>
    </Dialog>
  )
}
