import React from 'react'
import { useRemoteData } from '../../lib/RemoteData'
import { API_HOST } from '../../lib/environment'
import { useNavigate, useLocation, NavigateFunction } from 'react-router-dom'
import { AggregatedStatisticsPrBusinessUnit, BusinessUnit } from '../../services/Dtos'
import {
  parseLocationSearchAsQueryParameters,
  toQueryParamUrlString,
  updateQueryParamValue
} from '../../lib/QueryParamHelpers'
import styled from 'styled-components'
import { StatisticsCard, Statistics } from './StatisticsCard'
import { CloseIcon } from '../../components/icons'

type DataSelectionTypes = 'totalRvms' | 'totalLicenses' | 'invoicedRvms' | 'invoicedLicenses'
type BusinessUnitWithColor = BusinessUnit & { color: string; textColor: string }

// From color circle created by TOMRA Colors for pie chart
const colors: [string, string][] = [
  ['var(--colors-blue)', '#FFFFFF'],
  ['var(--colors-blue-light)', '#FFFFFF'],
  ['var(--colors-blue-dark)', '#FFFFFF'],
  ['var(--colors-green)', '#FFFFFF'],
  ['var(--colors-green-light)', 'var(--colors-black)'],
  ['var(--colors-pink)', 'var(--colors-black)'],
  ['var(--colors-orange)', '#FFFFFF'],
  ['var(--colors-yellow)', 'var(--colors-black)'],
  ['var(--colors-atlantic)', '#FFFFFF']
]

export const StatisticsOverviewPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { data: statisticsData } = useRemoteData<AggregatedStatisticsPrBusinessUnit>(API_HOST + '/statistics')
  const { data: businessUnits } = useRemoteData<BusinessUnit[]>(`${API_HOST}/businessunit`)

  if (businessUnits.type === 'loading' || statisticsData.type === 'loading') {
    return (
      <div className="card p-xl text-center">
        <div className="loadingSpinner mb-lg" />
        <div>Fetching and aggregating statistics (Can take up to 25s)</div>
      </div>
    )
  }

  if (businessUnits.type === 'error' || statisticsData.type === 'error') {
    return (
      <div className="centerAbsolute">
        <div className="alert alert-danger">Uh-oh.. Failed to load statistics</div>
      </div>
    )
  }

  const queryParameters = parseLocationSearchAsQueryParameters(location.search)
  const selectedBusinessUnits = queryParameters.find(value => value[0] === 'businessUnits')?.[1] ?? []
  const selectedProducts = queryParameters.find(value => value[0] === 'products')?.[1] ?? []

  const products = getUniqueProducts(statisticsData.value)
  const businessUnitIds = Object.keys(statisticsData.value)
  const availableBusinessUnits: BusinessUnitWithColor[] = businessUnits.value
    .filter(bu => businessUnitIds.includes(bu.id))
    .map((bu, idx) => {
      const chosenColor = colors[idx % colors.length]
      return {
        ...bu,
        color: chosenColor[0],
        textColor: chosenColor[1]
      }
    })

  const selectedData: DataSelectionTypes =
    (queryParameters.find(value => value[0] === 'selection')?.[1][0] as DataSelectionTypes) ?? 'totalRvms'

  const renderedBusinessUnits =
    selectedBusinessUnits.length > 0 ? selectedBusinessUnits : Object.keys(statisticsData.value)
  const renderedProducts = selectedProducts.length > 0 ? selectedProducts : products
  const productFilters = products.filter(p => !selectedProducts.includes(p))
  const businessUnitFilters = availableBusinessUnits.filter(bu => !selectedBusinessUnits.includes(bu.id))

  return (
    <div className="card p-md">
      <div>
        <div className="ml-md">
          <label htmlFor="valueType" className="mr-sm">
            Value:
          </label>
          <select
            id="valueType"
            className="select"
            onChange={({ target }) => {
              navigate(toQueryParamUrlString(updateQueryParamValue(queryParameters, 'selection', [target.value])))
            }}
          >
            <option value="totalRvms">Total RVMS</option>
            <option value="totalLicenses">Total Licenses</option>
            <option value="invoicedRvms">Invoiced RVMS</option>
            <option value="invoicedLicenses">Invoiced Licenses</option>
          </select>
        </div>
        <fieldset className="mt-md border p-md">
          <legend className="px-sm">Filter</legend>
          <div>
            <button
              className="btn"
              onClick={() => {
                const updated = updateQueryParamValue(
                  updateQueryParamValue(queryParameters, 'products', []),
                  'businessUnits',
                  []
                )
                navigate(toQueryParamUrlString(updated))
              }}
            >
              Clear
            </button>
          </div>
          <div className="my-md">
            <select
              className="select"
              value=""
              onChange={({ target }) => addBusinessUnit(selectedBusinessUnits, navigate, queryParameters)(target.value)}
            >
              <option value="" disabled>
                Add business unit
              </option>
              {businessUnitFilters.map(bu => {
                return (
                  <option key={bu.id} style={{ background: bu.color, color: bu.textColor }} value={bu.id}>
                    {bu.id + ' ' + bu.name}
                  </option>
                )
              })}
            </select>
          </div>
          <div>
            {selectedBusinessUnits.map(buId => {
              const businessUnit = availableBusinessUnits.find(bu => buId === bu.id)
              const color = businessUnit?.color ?? '#ffffff'
              const textColor = businessUnit?.textColor ?? '#121212'
              return (
                <Pill
                  key={buId}
                  style={{ backgroundColor: color, color: textColor }}
                  onClick={removeBusinessUnit(buId, selectedBusinessUnits, navigate, queryParameters)}
                >
                  <span className="pt-xs">{businessUnits.value.find(bu => bu.id === buId)?.name ?? 'UNKNOWN'}</span>
                  <CloseIcon color={textColor} />
                </Pill>
              )
            })}
          </div>
          <div className="my-md">
            <select
              className="select"
              value=""
              onChange={({ target }) => addProduct(selectedProducts, navigate, queryParameters)(target.value)}
            >
              <option value="" disabled>
                Add product
              </option>
              {productFilters.map(product => {
                return (
                  <option key={product} value={product}>
                    {product}
                  </option>
                )
              })}
            </select>
          </div>
          <div>
            {selectedProducts.map(product => {
              return (
                <ProductPill
                  key={product}
                  onClick={removeProduct(product, selectedProducts, navigate, queryParameters)}
                >
                  <span className="pt-xs">{product}</span>
                  <CloseIcon />
                </ProductPill>
              )
            })}
          </div>
        </fieldset>
      </div>

      <div className="mt-md flex flex-wrap gap-md w-full">
        {renderedProducts.map(product => {
          const dataSet = renderedBusinessUnits
            .map(businessUnitId => {
              const count = statisticsData.value[businessUnitId]?.[product]?.[selectedData] ?? 0
              const businessUnit = availableBusinessUnits.find(bu => bu.id === businessUnitId)!! // This is dangerous

              return {
                title: businessUnit.name,
                value: count,
                color: businessUnit.color,
                textColor: businessUnit.textColor
              } as Statistics
            })
            .filter(({ value }) => value !== 0)

          return dataSet.length > 0 ? <StatisticsCard key={product} dataSet={dataSet} product={product} /> : null
        })}
      </div>
    </div>
  )
}

const removeBusinessUnit =
  (
    businessUnitId: string,
    selectedBusinessUnits: string[],
    navigate: NavigateFunction,
    queryParameters: [string, string[]][]
  ) =>
  () => {
    navigate(
      toQueryParamUrlString(
        updateQueryParamValue(
          queryParameters,
          'businessUnits',
          selectedBusinessUnits.filter(id => id !== businessUnitId)
        )
      )
    )
  }

const addBusinessUnit =
  (selectedBusinessUnits: string[], navigate: NavigateFunction, queryParameters: [string, string[]][]) =>
  (businessUnitId: string) => {
    navigate(
      toQueryParamUrlString(
        updateQueryParamValue(queryParameters, 'businessUnits', [...selectedBusinessUnits, businessUnitId])
      )
    )
  }

const removeProduct =
  (
    selectedProduct: string,
    selectedProducts: string[],
    navigate: NavigateFunction,
    queryParameters: [string, string[]][]
  ) =>
  () => {
    navigate(
      toQueryParamUrlString(
        updateQueryParamValue(
          queryParameters,
          'products',
          selectedProducts.filter(product => product !== selectedProduct)
        )
      )
    )
  }

const addProduct =
  (selectedProducts: string[], navigate: NavigateFunction, queryParameters: [string, string[]][]) =>
  (selectedProduct: string) => {
    navigate(
      toQueryParamUrlString(updateQueryParamValue(queryParameters, 'products', [...selectedProducts, selectedProduct]))
    )
  }

const Pill = styled.button`
  display: flex;
  align-items: center;
  margin-left: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
  font-size: var(--text-md);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--radius-large);
`

const ProductPill = styled(Pill)`
  background-color: var(--colors-limestone);
`

const getUniqueProducts = (data: AggregatedStatisticsPrBusinessUnit): string[] => {
  let products: string[] = Object.values(data).flatMap(buProducts => Object.keys(buProducts))
  return Array.from(new Set(products))
}
