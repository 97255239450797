import React, { createRef, FunctionComponent, useLayoutEffect } from 'react'

type Props = {
  animate?: boolean
  children?: React.ReactNode
}

export const AnimateHeight: FunctionComponent<Props> = ({ children, animate = true }) => {
  const ref = createRef<HTMLDivElement>()

  useLayoutEffect(() => {
    if (ref?.current) {
      const target = ref.current as any

      const value = animate ? target.firstElementChild.offsetHeight + 'px' : '0px'

      if (target.style.getPropertyValue('--calc-height') !== value) {
        target.style.setProperty('--calc-height', value)
      }
    }
  }, [ref, animate])

  return (
    <div
      style={{
        height: 'var(--calc-height)',
        transition: 'height 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
        overflow: animate ? 'hidden' : 'initial'
      }}
      ref={ref}
    >
      <div>{animate ? children : null}</div>
    </div>
  )
}
