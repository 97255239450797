export const triggerDownload = (blob: Blob, filename: string) => {
  try {
    const link = document.createElement('a')

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } catch (e) {
    throw new Error(`Error while triggering file download`)
  }
}

export const createCsvFromData = (data: { [key: string]: any }, csvHeaders: string) => {
  return data.reduce((acc: any, user: { [key: string]: any }) => {
    const row = Object.values(user).join(',')
    return `${acc}\n${row}`
  }, `${csvHeaders}`)
}
