import React from 'react'
import { colors } from '../styles'
import { AppMenuMountDiv } from './AppMenuMountDiv'
import Logo from './icons/Logo'
import { NavLink } from 'react-router-dom'
import { LogoutIcon } from './icons'
import { userIsAdmin } from '../lib/jwt'
import { authentication } from '../lib/authentication'

export const Header = () => {
  return (
    <div className="navbar">
      <div className="grid grid-cols-12 gap-x-md mx-auto w-full max-content-width px-md">
        <div className="flex items-center gap-x-md col-span-3">
          <Logo />
          <a href="/">Account Manager</a>
        </div>

        <div className="flex col-span-7">
          <NavLink to="/accounts" className={({ isActive }) => 'navtab flex-1' + (isActive ? ' active' : '')}>
            Accounts
          </NavLink>
          <NavLink to="/users" className={({ isActive }) => 'navtab flex-1' + (isActive ? ' active' : '')}>
            Users
          </NavLink>
          {userIsAdmin() && (
            <NavLink to="/statistics" className={({ isActive }) => 'navtab flex-1' + (isActive ? ' active' : '')}>
              Statistics
            </NavLink>
          )}
          <NavLink to="/products" className={({ isActive }) => 'navtab flex-1' + (isActive ? ' active' : '')}>
            Products
          </NavLink>
        </div>

        <div className="flex gap-x-md items-center justify-end col-span-1 col-start-12">
          <AppMenuMountDiv />

          <button onClick={authentication.logout}>
            <LogoutIcon color={colors.WHITE} />
          </button>
        </div>
      </div>
    </div>
  )
}
