import React, { FunctionComponent } from 'react'
import { ArrowDownIcon, ArrowUpIcon } from './icons'

type Props = {
  key?: any
  onClick: Function
  order: 'asc' | 'desc' | null
  name: string
  className?: string
  children: any
}

export const SortableTh: FunctionComponent<Props> = ({ order, onClick, name, children }) => {
  return (
    <th>
      <a
        href="/#"
        onClick={e => {
          e.preventDefault()
          order === 'asc' ? onClick('desc', name) : onClick('asc', name)
        }}
      >
        <div className="flex items-center">
          {children}
          {order === 'asc' ? <ArrowUpIcon size="20" /> : order === 'desc' ? <ArrowDownIcon size="20" /> : null}
        </div>
      </a>
    </th>
  )
}
