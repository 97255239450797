import React, { useState } from 'react'
import { CloseIcon } from '../icons'

type Props = {
  search: (searchTerm: string) => void
  clearResults: () => void
  isSearching: boolean
  label: string
  placeholder: string
}

export const Search = ({ search, clearResults, isSearching, label, placeholder }: Props) => {
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <form
      onSubmit={e => {
        search(searchTerm)
        e.preventDefault()
      }}
      onReset={e => {
        clearResults()
        setSearchTerm('')
        e.preventDefault()
      }}
    >
      <label htmlFor="searchField" className="block mb-sm">
        {label}
      </label>

      <div className="flex gap-md">
        <div className="relative flex items-center">
          <input
            id="searchField"
            type="text"
            autoComplete="off"
            className=" pr-lg w-20"
            value={searchTerm}
            onChange={e => {
              const term = e.target.value
              if (term !== searchTerm) {
                clearResults()
              }
              setSearchTerm(term)
            }}
            placeholder={placeholder}
          />

          <button type="reset" style={{ position: 'absolute', right: 0 }}>
            <CloseIcon size="24" />
          </button>
        </div>

        <button type="submit" className="btn btn-primary-dark" disabled={!searchTerm}>
          {isSearching && <div className="loadingSpinner" />}
          Search
        </button>
      </div>
    </form>
  )
}
