import React, { useState } from 'react'
import { CardDialog } from '../../../components/CardDialog'
import { StagedUser } from '../../../dataTypes'
import { AccountDetailsDto, FullLicense, ProductWithRolesDto } from '../../../services/Dtos'
import { CreateUserForm } from './CreateUserForm'
import { useUserSearch } from '../../../lib/UserHooks'
import { Search } from '../../../components/policy/Search'
import { colors } from '../../../styles'
import { API_HOST } from '../../../lib/environment'
import { authenticatedGet } from '../../../lib/httpClient'
import { logError } from '../../../lib/logError'
import { LicenseService } from '../../../services/LicenseService'

type Props = {
  license: FullLicense
  productsWithRoles: ProductWithRolesDto[]
  onSaved: () => void
}

const productToRedirectMap: { [key: string]: { uri: string; clientId: string } | undefined } = {
  NotifyAssist: {
    uri: 'https://notifyassist.tomra.cloud',
    clientId: 'dashboard'
  },
  NotifyAssistPremium: {
    uri: 'https://notifyassist.tomra.cloud',
    clientId: 'dashboard'
  }
}

function errorFromMaybeStatus(err: any): string {
  if (err?.status === 409) {
    return 'The user is already active on this license'
  }
  return err?.body?.message || err?.message || 'Something went wrong'
}

export const AddUserForm = ({ license, productsWithRoles, onSaved }: Props) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { searchResult, search, resetSearch } = useUserSearch()
  const [showCreateUserDialog, setShowCreateUserDialog] = useState(false)
  const productWithRoles = productsWithRoles.find(p => p.product === license.productName)
  const availableRoles = [' '].concat(productWithRoles ? productWithRoles.roles : [])
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const isValid = email && role
  const searchHits = searchResult.type === 'success' ? searchResult.value : []
  const isLoading = searchResult.type === 'loading' || searchResult.type === 'reloading'
  const [existingProductAccountsForSelectedUser, setExistingProductAccountsForSelectedUser] = useState<
    AccountDetailsDto | undefined
  >()

  const findExistingProductAccountsForUser = (product: string, user: string) => {
    setStatus('pending')
    authenticatedGet(`${API_HOST}/account/${product}/${user}`)
      .run()
      .then(response => {
        setStatus('idle')
        setExistingProductAccountsForSelectedUser(response)
      })
      .catch(error => {
        setStatus('failure')
        logError(new Error('Failed to find any accounts for selected user'), error)
        return false
      })
  }

  const submitUser = (formEmail: string, formFirstName: string, formLastName: string, formRole: string) => {
    setIsSubmitting(true)
    const stagedUser: StagedUser = {
      email: formEmail,
      firstName: formFirstName,
      lastName: formLastName,
      role: formRole,
      product: license.productName
    }
    LicenseService.addUserToLicense(license.id, stagedUser)
      .then(_ => {
        setIsSubmitting(false)
        onSaved()
      })
      .catch(err => {
        setIsSubmitting(false)
        setErrorMessage('Error: ' + errorFromMaybeStatus(err))
      })
  }

  return (
    <div
      className="p-lg flex flex-col gap-lg items-start"
      style={{
        maxHeight: '90vh',
        width: '40rem'
      }}
    >
      <h1 className="text-xl">Add user to {license.productName} license</h1>

      <Search
        search={search}
        clearResults={resetSearch}
        isSearching={isLoading}
        label="Search"
        placeholder="E-mail or name"
      />

      {searchHits.length > 0 ? (
        <ul
          className="overflow-y-auto border border-grey rounded-sm"
          style={{
            maxHeight: '15vh'
          }}
        >
          {searchHits.map(foundUser => {
            return (
              <li key={foundUser.email}>
                <button
                  className="text-left p-sm hover:bg-sand transition w-full"
                  onClick={() => {
                    resetSearch()
                    findExistingProductAccountsForUser(license.productName, foundUser.id)
                    setEmail(foundUser.email)
                    setFirstName(foundUser.firstName)
                    setLastName(foundUser.lastName)
                  }}
                >
                  {foundUser.firstName + ' ' + foundUser.lastName} &lt;{foundUser.email}&gt; ({foundUser.numOfLicenses}{' '}
                  licenses)
                </button>
              </li>
            )
          })}
        </ul>
      ) : null}

      <button
        className="btn"
        onClick={() => {
          resetSearch()
          setShowCreateUserDialog(true)
        }}
      >
        Create User
      </button>
      {status === 'failure' && <div> Failed to get any accounts for specific user </div>}
      {email && (
        <div>
          <div className="font-bold">Selected User</div>
          <div>{`${firstName} ${lastName} (${email})`}</div>
        </div>
      )}
      {email &&
        existingProductAccountsForSelectedUser !== undefined &&
        existingProductAccountsForSelectedUser.accountDetails?.length > 0 && (
          <div style={{ color: `${colors.DARK_RED}` }}>
            {email} already has license(s) for {license.productName}:&nbsp;
            {existingProductAccountsForSelectedUser.accountDetails.map((account, index) => {
              return (
                <div className="inline-block" key={'Account' + index}>
                  <span>&nbsp;{index ? 'and' : ''}&nbsp;</span>
                  <a
                    className="text-blue font-bold"
                    href={'/accounts/' + account.id}
                    onClick={() => {
                      setShowCreateUserDialog(false)
                    }}
                  >
                    {account.name}
                  </a>
                </div>
              )
            })}
            . A user can only have access to one {license.productName} account. Please remove users from accounts where
            they should no longer have access.
          </div>
        )}

      <div>
        <label htmlFor="selectRole" className="block font-bold mb-xs">
          Select Role
        </label>

        <select
          id="selectRole"
          className="select"
          onChange={e => setRole(e.target.value)}
          value={role || availableRoles[0]}
        >
          {availableRoles.map(availableRole => (
            <option key={availableRole} value={availableRole}>
              {availableRole}
            </option>
          ))}
        </select>
      </div>

      {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}

      <button
        className="btn btn-primary-dark self-end"
        onClick={() => submitUser(email, firstName, lastName, role)}
        disabled={!isValid}
      >
        {isSubmitting ? <div className="loadingSpinner" /> : 'Add user to license'}
      </button>

      {showCreateUserDialog && (
        <CardDialog
          onClose={() => {
            setShowCreateUserDialog(false)
          }}
        >
          <CreateUserForm
            redirect={productToRedirectMap[license.productName]}
            onCreated={(email: string, firstName: string, lastName: string) => {
              setEmail(email)
              setFirstName(firstName)
              setLastName(lastName)
              setShowCreateUserDialog(false)
            }}
          />
        </CardDialog>
      )}
    </div>
  )
}
