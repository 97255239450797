import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { authenticatedGet } from '../../../lib/httpClient'
import { API_HOST } from '../../../lib/environment'
import { logError } from '../../../lib/logError'
import { UsersForLicense } from './UsersForLicense'
import { ProductLicenseRow } from './ProductLicenseRow'
import { createCsvFromData, triggerDownload } from '../../../lib/csvUtils'
import { ProductLicenseTable } from './ProductLicenseTable'
import { ArrowLeftIcon } from '../../../components/icons'

export const ProductDetailPage = () => {
  const { product = '' } = useParams<{ product: string }>()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [licenses, setLicenses] = useState<any[]>([])
  const [activeLicenseRows, setActiveLicenseRows] = useState<string[]>([])
  const [exportStatus, setExportStatus] = useState<'idle' | 'pending' | 'failure'>('idle')

  useEffect(() => {
    setStatus('pending')

    const f = authenticatedGet(`${API_HOST}/product/${product}/licenses`)

    f.run()
      .then(response => {
        setStatus('idle')
        setLicenses(response)
      })
      .catch(error => {
        setStatus('failure')
        logError(new Error('Unable to load licenses'), error)
      })

    return () => f.abort()
  }, [product])

  const toggleRow = (id: string, isActive: boolean) => {
    !isActive
      ? setActiveLicenseRows(active => unique([...active, id]))
      : setActiveLicenseRows(active => active.filter(a => a !== id))
  }

  const createBlob = (data: string) => new Blob([data], { type: 'text/csv;charset=utf-8;' })

  const exportUsers = () => {
    try {
      setExportStatus('pending')

      const headers = 'Product,Business Unit ID,Business Unit Name,Account,E-mail,Name,Role'
      const data = licenses.flatMap(getLicenseUsersForProduct(product))

      const csv = createCsvFromData(data, headers)
      const blob = createBlob(csv)
      const filename = `${product.toLowerCase()}-users-${new Date().toISOString()}.csv`

      triggerDownload(blob, filename)

      setExportStatus('idle')
    } catch (error: any) {
      logError(new Error('Unable to create user list'), error)
      setExportStatus('failure')
    }
  }

  return (
    <div className="flex flex-col gap-md">
      <div className="flex items-center justify-between">
        <Link to="/products" className="flex items-center gap-sm text-blue">
          <ArrowLeftIcon color="var(--colors-blue)" />
          <h1 className="text-xl">{product}</h1>
        </Link>

        <button className="btn" onClick={exportUsers} disabled={exportStatus === 'pending'}>
          {exportStatus === 'pending' ? <div className="loadingSpinner" /> : 'Export user list'}
        </button>
      </div>

      {exportStatus === 'failure' && <div className="alert alert-danger">Error while triggering file download</div>}

      {status === 'pending' ? (
        <div className="loadingSpinner" />
      ) : status === 'failure' ? (
        <div className="alert alert-danger">Unable to load licenses</div>
      ) : (
        <div className="card flex-col gap-md">
          <h2 className="m-md">Licenses</h2>

          {licenses.length === 0 ? (
            <div className="p-md">There are no licenses for {product} yet.</div>
          ) : (
            <ProductLicenseTable>
              {licenses.map(license => {
                const { id, users } = license
                const isActiveRow = activeLicenseRows.some(activeId => id === activeId)

                return (
                  <React.Fragment key={license.id}>
                    <ProductLicenseRow
                      isActive={isActiveRow}
                      license={license}
                      onShowUsers={() => toggleRow(id, isActiveRow)}
                    />

                    <UsersForLicense isActive={isActiveRow} users={users} />
                  </React.Fragment>
                )
              })}
            </ProductLicenseTable>
          )}
        </div>
      )}
    </div>
  )
}

const unique = (array: string[]) => [...Array.from(new Set(array))]

const getLicenseUsersForProduct = (product: string) => {
  return (license: any) =>
    license.users.map((user: any) => ({
      product,
      business_unit_id: license.business_unit_id,
      business_unit_name: license.business_unit_name,
      account: `${license.account_name} (${license.external_id})`,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      role: user.role
    }))
}
