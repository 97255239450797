import React from 'react'
import { BusinessUnit, FullAccountDto, ProductWithRolesDto } from '../../services/Dtos'
import { Form, Link, useNavigation, useRouteError, useRouteLoaderData } from 'react-router-dom'

export const EditAccountDetails = () => {
  const error = useRouteError() as { message: string }
  const navigation = useNavigation()
  const businessUnits = useRouteLoaderData('withBusinessUnits') as BusinessUnit[]
  const { fullAccount } = useRouteLoaderData('account') as {
    fullAccount: FullAccountDto
    productsWithRoles: ProductWithRolesDto[]
  }
  const { account } = fullAccount
  const businessUnit = businessUnits.find(bu => bu.id === account.businessUnitId)
  if (!businessUnit) {
    return (
      <div className="centerAbsolute">
        <div className="alert alert-danger">
          Uh-oh.. couldn't find business unit {account.businessUnitId} - please try again or report error to support
        </div>
      </div>
    )
  }
  return (
    <Form action="." method="put" className="card p-md flex flex-col space-y-md items-start">
      <h2 className="text-xl">Edit Account Details</h2>

      <div>
        <label htmlFor="accountName" className="mb-xs text-stone block">
          Name
        </label>
        <input name="name" defaultValue={account.name} id="accountName" type="text" required={true} />
      </div>

      <div>
        <label htmlFor="accountBu" className="mb-xs text-stone block">
          Business Unit
        </label>
        <select
          id="accountBu"
          name="businessUnitId"
          className="border border-grey bg-grey-light rounded-sm p-sm"
          defaultValue={account.businessUnitId}
        >
          {businessUnits.map((bu: any) => (
            <option key={bu.id} value={bu.id}>
              {bu.id} {bu.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="accountDescription" className="mb-xs text-stone block">
          Description
        </label>
        <input id="accountDescription" name="description" type="text" defaultValue={account.description} />
      </div>

      {error && <div className="alert alert-danger w-full">{error.message}</div>}

      <div className="flex space-x-md w-full">
        <button type="submit" className="btn btn-primary-dark" disabled={navigation.state !== 'idle'}>
          {navigation.state !== 'idle' ? <div className="loadingSpinner" data-testid="loading" /> : 'Save'}
        </button>
        <Link className="btn" to="..">
          Cancel
        </Link>
      </div>
    </Form>
  )
}
