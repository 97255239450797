import { Link } from 'react-router-dom'
import React, { FunctionComponent } from 'react'
import { ArrowRightIcon } from '../../../components/icons'

type Props = {
  license: any
  isActive: boolean
  onShowUsers: () => void
}

export const ProductLicenseRow: FunctionComponent<Props> = ({ license, isActive, onShowUsers }) => {
  const { business_unit_id, business_unit_name, account_id, account_name, external_id, invoiced_from, users } = license

  return (
    <tr
      className="transition"
      style={isActive ? { backgroundColor: 'var(--colors-wind)', borderColor: 'var(--colors-wind)' } : {}}
    >
      <td width="5%">
        <button className="btn btn-icon" onClick={onShowUsers}>
          <div
            className={isActive ? 'delay-0' : 'transition delay-500'}
            aria-label={isActive ? 'Hide license users' : 'Show license users'}
            style={isActive ? { transform: 'rotate(90deg)' } : { transform: 'rotate(0)' }}
          >
            <ArrowRightIcon />
          </div>
        </button>
      </td>
      <td>
        <Link className="text-blue font-bold" to={`/businessunits/${business_unit_id}`}>
          {business_unit_name} ({business_unit_id})
        </Link>
      </td>
      <td>
        <Link className="text-blue font-bold" to={`/accounts/${account_id}`}>
          {account_name} ({external_id})
        </Link>
      </td>
      <td className="flex gap-md items-center">{users.length}</td>
      <td>{invoiced_from ? `Invoiced from ${new Date(invoiced_from).toLocaleDateString()}` : '-'}</td>
    </tr>
  )
}
