import React from 'react'
import {
  createBrowserRouter,
  Link,
  Navigate,
  Outlet,
  redirect,
  RouteObject,
  RouterProvider,
  useRouteError
} from 'react-router-dom'
import { Header } from './components/Header'
import { API_HOST, VERSION } from './lib/environment'
import { AccountDetailsPage } from './pages/account-details/AccountDetailsPage'
import { AccountsOverviewPage } from './pages/accounts-overview/AccountsOverviewPage'
import { UsersOverviewPage } from './pages/users-overview/UsersOverviewPage'
import { UserDetailsPage } from './pages/user-details/UserDetailsPage'
import { StatisticsOverviewPage } from './pages/statistics-overview/StatisticsOverviewPage'
import { BusinessUnitDetailsPage } from './pages/business-unit-details/BusinessUnitDetailsPage'
import { ProductsOverviewPage } from './pages/products/ProductsOverviewPage'
import { ProductDetailPage } from './pages/products/detail/ProductDetailPage'
import {
  DeactivatedAccountsPage,
  DeleteDeactivatedAccountDialog,
  ReactivateAccountDialog
} from './pages/deactivated-accounts/DeactivatedAccountsPage'
import { authenticatedGet } from './lib/httpClient'
import * as AccountService from './services/AccountsService'
import { deleteAccount, editAccount, fetchBusinessUnits } from './services/AccountsService'
import { LicenseService } from './services/LicenseService'
import { ViewAccountDetails } from './pages/account-details/ViewAccountDetails'
import { EditAccountDetails } from './pages/account-details/EditAccountDetails'
import { FullAccountDto, ProductWithRolesDto } from './services/Dtos'

export type AccountAndProducts = {
  fullAccount: FullAccountDto
  productsWithRoles: ProductWithRolesDto[]
}

function Root() {
  return (
    <>
      <Header />
      <div className="max-content-width mx-auto mt-md">
        <Outlet />
      </div>
      <div className="my-md max-content-width mx-auto">Version: {VERSION}</div>
    </>
  )
}

function GeneralError() {
  const error = useRouteError() as any
  const errorMessage =
    error?.status === 403
      ? 'Your user does not have permission to show this page'
      : error?.body?.message || error?.message || 'Uknkown error'
  return <div className="alert alert-danger m-lg">Error: {errorMessage}</div>
}

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    children: [
      {
        id: 'rootErrorHandler',
        errorElement: <GeneralError />,
        children: [
          {
            id: 'withBusinessUnits',
            loader: async () => {
              return await fetchBusinessUnits()
            },
            shouldRevalidate: () => false,
            children: [
              {
                path: 'accounts/deactivated',
                element: <DeactivatedAccountsPage />,
                loader: async () => {
                  const deactivatedAccounts = await authenticatedGet(
                    `${API_HOST}/account?accountState=DEACTIVATED`
                  ).run()
                  // TODO: make this a property of above response objects
                  const accountsWithInvoicedLicenses = await authenticatedGet(
                    `${API_HOST}/account/deactivated/invoiced-licenses`
                  ).run()
                  return {
                    accounts: deactivatedAccounts.accounts,
                    accountsWithInvoicedLicenses: accountsWithInvoicedLicenses.inactiveAccounts
                  }
                },
                children: [
                  {
                    path: ':accountId/reactivate',
                    element: <ReactivateAccountDialog />,
                    errorElement: <ReactivateAccountDialog />,
                    action: async ({ params, request }) => {
                      const data = Object.fromEntries(await request.formData())
                      await editAccount(params.accountId!, data)
                      return redirect(`/accounts/deactivated?reactivated=${params.accountId}`)
                    }
                  },
                  {
                    path: ':accountId/delete',
                    element: <DeleteDeactivatedAccountDialog />,
                    errorElement: <DeleteDeactivatedAccountDialog />,
                    action: async ({ params, request }) => {
                      await deleteAccount(params.accountId!)
                      return redirect(`/accounts/deactivated?deleted=${params.accountId}`)
                    }
                  }
                ]
              },
              {
                id: 'account',
                path: 'accounts/:accountId',
                element: <AccountDetailsPage />,
                loader: async ({ params }) => {
                  const allData = await Promise.all([
                    AccountService.fetchAccount(params.accountId!),
                    LicenseService.fetchAvailableProductsWithRoles()
                  ])
                  return { fullAccount: allData[0], productsWithRoles: allData[1] }
                },
                errorElement: <AccountDetailsPage />,
                children: [
                  { index: true, element: <ViewAccountDetails />, errorElement: <ViewAccountDetails /> },
                  {
                    path: 'edit',
                    element: <EditAccountDetails />,
                    errorElement: <EditAccountDetails />,
                    action: async ({ request, params }) => {
                      const data = Object.fromEntries(await request.formData())
                      await editAccount(params.accountId!, data)
                      return redirect(`/accounts/${params.accountId}?saved=true`)
                    }
                  }
                ]
              },
              {
                path: 'accounts',
                element: <AccountsOverviewPage />,
                loader: async ({ request }) => {
                  const showOnlyInactive = new URL(request.url).searchParams.get('onlyInactive')
                  if (showOnlyInactive) {
                    return await AccountService.fetchAccountsWithoutActiveInstallations()
                  } else {
                    return await AccountService.fetchAccounts()
                  }
                }
              },
              {
                path: 'businessunits/:id',
                element: <BusinessUnitDetailsPage />
              }
            ]
          },
          {
            path: 'users/:id',
            element: <UserDetailsPage />
          },
          {
            path: 'users',
            element: <UsersOverviewPage />
          },
          {
            path: 'products/:product',
            element: <ProductDetailPage />
          },
          {
            path: 'products',
            element: <ProductsOverviewPage />
          },
          {
            path: 'statistics',
            element: <StatisticsOverviewPage />
          },
          {
            index: true,
            element: <Navigate to="/accounts" replace={true} />
          },
          {
            // Redirect for old bookmarks
            path: 'manage/*',
            element: <Navigate to="/accounts" replace={true} />
          }
        ]
      },
      {
        path: '*',
        element: (
          <div className="alert alert-danger m-lg">
            <h1 className="text-lg">This page does not exist</h1>
            <Link className="link" to="/accounts">
              Go to start page
            </Link>
          </div>
        )
      }
    ]
  }
]

export function App() {
  const router = createBrowserRouter(routes)
  return <RouterProvider router={router} />
}
