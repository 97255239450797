import React, { useState } from 'react'
import { CardDialog } from '../../../components/CardDialog'
import type {
  BusinessUnit,
  FullLicense,
  License,
  ProductWithRolesDto,
  TransitionToNewProduct
} from '../../../services/Dtos'
import { LicenseService } from '../../../services/LicenseService'
import { AddAliasForm } from './AddAliasForm'
import { SpecifyDateForm } from './SpcifyDateForm'
import { HoverMenu, MenuButton } from '../../../components/HoverMenu'
import TransitionToNewProductForLicenseForm from './TransitionToNewProductForLicenseForm'
import { DeleteIcon } from '../../../components/icons'
import { ConfirmActionDialog } from '../ConfirmActionDialog'
import { createPortal } from 'react-dom'
import { userIsAdmin, userIsMarketAdmin } from '../../../lib/jwt'

type Props = {
  licenses: FullLicense[]
  products: ProductWithRolesDto[]
  businessUnit: BusinessUnit
  onEditLicenseScope: (licenseId: string) => void
  onViewUsersForLicense: (licenseId: string) => void
  onAddUserForLicense: (licenseId: string) => void
  onSaved: () => void
}

export const ViewLicenseTable = ({
  licenses,
  products,
  businessUnit,
  onEditLicenseScope,
  onViewUsersForLicense,
  onAddUserForLicense,
  onSaved
}: Props) => {
  const [addAliasForLicenseId, setLicenseIdToAddAlias] = useState<string | undefined>()
  const [addExpiryForLicense, setAddExpiryForLicense] = useState<FullLicense>()
  const [addInvoicingForLicenseId, setAddInvoicingForLicenseId] = useState<string | undefined>()
  const [updateForLicenseId, setUpdateForLicenseId] = useState<string>('')
  const [transitionToNewProduct, setTransitionToNewProduct] = useState<TransitionToNewProduct>()
  const [showConfirmRemoveAlias, setShowConfirmRemoveAlias] = useState('')
  const [showConfirmDeleteLicenseId, setShowConfirmDeleteLicenseId] = useState('')

  return (
    <React.Fragment>
      <table className="table" style={{ tableLayout: 'auto' }}>
        <thead>
          <tr>
            <th>Aliases</th>
            <th>Product</th>
            <th># of Users</th>
            <th>Status</th>
            <th>Start Date</th>
            <th>Expiry Date</th>
            <th>Ordered by</th>
            <th>Support Ticket #</th>
            <th>Invoiced From</th>
            <th>Actions</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {licenses.map((accountLicense, idx) => {
            const transitionableProduct = accountLicense.productName.includes('NotifyAssist')
              ? accountLicense.productName === 'NotifyAssistPremium'
                ? 'NotifyAssist'
                : 'NotifyAssistPremium'
              : null
            const expired = (accountLicense.to && new Date(accountLicense.to).valueOf() <= Date.now()) as boolean
            const deactivated = 'deactivated' in accountLicense && accountLicense.deactivated !== null
            const product = products.find(p => p.product === accountLicense.productName)
            if (!product) {
              return (
                <tr>
                  <td colSpan={100}>Unable to find product {accountLicense.productName} for license</td>
                </tr>
              )
            }

            return (
              <tr key={accountLicense.id} className={deactivated ? 'bg-wind' : expired ? 'bg-limestone' : ''}>
                <td>
                  {accountLicense.aliases.length === 0
                    ? '-'
                    : accountLicense.aliases.map(alias => {
                        return (
                          <div key={alias}>
                            {alias}
                            <button
                              aria-label={`Delete ${alias}`}
                              className="btn btn-icon"
                              disabled={expired}
                              title={expired ? `Alias ${alias} cannot be deleted as the license is expired` : ''}
                              onClick={e => {
                                e.preventDefault()
                                setShowConfirmRemoveAlias(alias)
                              }}
                              style={{ cursor: 'pointer', verticalAlign: 'middle', display: 'inline' }}
                            >
                              <DeleteIcon color="var(--colors-red)" />
                            </button>
                          </div>
                        )
                      })}
                </td>
                <td>{accountLicense.productName}</td>
                <td>{accountLicense.users.length}</td>
                <td>{getLicenseStatus(accountLicense)}</td>
                <td>{new Date(accountLicense.from).toISOString().slice(0, 10)}</td>
                <td>
                  {accountLicense.to
                    ? new Date(accountLicense.to).toISOString().slice(0, 10)
                    : accountLicense.deactivated
                    ? new Date(accountLicense.deactivated).toISOString().slice(0, 10)
                    : 'Does not expire'}
                </td>
                <td>{accountLicense.orderedBy}</td>
                <td>{accountLicense.ticketNo}</td>
                <td>
                  {accountLicense.invoicedFrom
                    ? new Date(accountLicense.invoicedFrom).toISOString().slice(0, 10)
                    : !product.invoiceable || !businessUnit.invoiceable
                    ? 'Not invoiceable'
                    : 'Is not invoiced'}
                </td>
                <td>
                  {(!('deactivated' in accountLicense) || accountLicense.deactivated === null) && (
                    <HoverMenu id={`actionmenu-${idx}`}>
                      {(userIsAdmin() ||
                        (userIsMarketAdmin() &&
                          products.find(p => p.product === accountLicense.productName)?.availableForMarketAdmin)) && (
                        <MenuButton text="Add User" onClick={() => onAddUserForLicense(accountLicense.id)} />
                      )}
                      <MenuButton text="View Users" onClick={() => onViewUsersForLicense(accountLicense.id)} />
                      {transitionableProduct && (
                        <MenuButton
                          text={transitionableProduct === 'NotifyAssist' ? 'Downgrade' : 'Upgrade to Premium'}
                          onClick={() => {
                            setTransitionToNewProduct({
                              orderedBy: accountLicense.orderedBy,
                              ticketNo: accountLicense.ticketNo,
                              targetProduct: transitionableProduct
                            })
                            setUpdateForLicenseId(accountLicense.id)
                          }}
                        />
                      )}

                      {userIsAdmin() && (
                        <MenuButton
                          text="Configure Access"
                          onClick={() => {
                            onEditLicenseScope(accountLicense.id)
                          }}
                        />
                      )}

                      <MenuButton text="Add Alias" onClick={() => setLicenseIdToAddAlias(accountLicense.id)} />

                      {!accountLicense.to ? (
                        <MenuButton text="Expire License" onClick={() => setAddExpiryForLicense(accountLicense)} />
                      ) : (
                        <MenuButton text="Set expiry date" onClick={() => setAddExpiryForLicense(accountLicense)} />
                      )}

                      {!accountLicense.invoicedFrom && product.invoiceable && businessUnit.invoiceable && (
                        <MenuButton
                          text="Start Invoicing"
                          onClick={() => setAddInvoicingForLicenseId(accountLicense.id)}
                        />
                      )}
                    </HoverMenu>
                  )}
                </td>
                <td>
                  {(!('deactivated' in accountLicense) || accountLicense.deactivated === null) && (
                    <button
                      aria-label={`Delete ${accountLicense.productName} license`}
                      className="btn btn-icon"
                      onClick={e => {
                        e.preventDefault()
                        setShowConfirmDeleteLicenseId(accountLicense.id)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <DeleteIcon color="var(--colors-red)" />
                    </button>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {addAliasForLicenseId && (
        <CardDialog
          onClose={() => {
            setLicenseIdToAddAlias(undefined)
          }}
        >
          <AddAliasForm
            licenseId={addAliasForLicenseId}
            onComplete={() => {
              onSaved()
              setLicenseIdToAddAlias(undefined)
            }}
          />
        </CardDialog>
      )}

      {showConfirmRemoveAlias &&
        createPortal(
          <ConfirmActionDialog
            message={`This will remove the alias '${showConfirmRemoveAlias}'. Are you sure you want to continue?`}
            onConfirm={() => LicenseService.removeAlias(showConfirmRemoveAlias).then(onSaved)}
            onDone={() => setShowConfirmRemoveAlias('')}
          />,
          document.getElementById('modal-root')!
        )}

      {transitionToNewProduct && (
        <CardDialog
          onClose={() => {
            setTransitionToNewProduct(undefined)
          }}
        >
          <TransitionToNewProductForLicenseForm
            licenseId={updateForLicenseId}
            transitionToNewProduct={transitionToNewProduct}
            onSaved={() => {
              setTransitionToNewProduct(undefined)
              onSaved()
            }}
          />
        </CardDialog>
      )}

      {addExpiryForLicense && (
        <CardDialog
          onClose={() => {
            setAddExpiryForLicense(undefined)
          }}
        >
          <SpecifyDateForm
            title="Set expiry date for license"
            initialDate={addExpiryForLicense.to ? new Date(addExpiryForLicense.to) : new Date()}
            onComplete={async date => {
              await LicenseService.expireLicenseAtDate(addExpiryForLicense.id, date)
              onSaved()
              setAddExpiryForLicense(undefined)
            }}
            allowClearing={true}
          />
        </CardDialog>
      )}

      {addInvoicingForLicenseId && (
        <CardDialog
          onClose={() => {
            setAddInvoicingForLicenseId(undefined)
          }}
        >
          <SpecifyDateForm
            title="Start invoicing from date for license"
            onComplete={async date => {
              await LicenseService.startInvoicingAtDate(addInvoicingForLicenseId, date)
              onSaved()
              setAddInvoicingForLicenseId(undefined)
            }}
          />
        </CardDialog>
      )}

      {showConfirmDeleteLicenseId &&
        createPortal(
          <ConfirmActionDialog
            message="This will remove this license and deactivate all user access associated with this license. Are you sure you want to continue?"
            onConfirm={() => LicenseService.disableLicense(showConfirmDeleteLicenseId).then(onSaved)}
            onDone={() => setShowConfirmDeleteLicenseId('')}
          />,
          document.getElementById('modal-root')!
        )}
    </React.Fragment>
  )
}

function getLicenseStatus(accountLicense: License) {
  if (accountLicense.deactivated) {
    return 'Deactivated'
  }
  if (accountLicense.to && Date.parse(accountLicense.to) < Date.now()) {
    return 'Expired'
  }
  if (Date.parse(accountLicense.from) > Date.now()) {
    return 'Not started'
  }

  return 'Active'
}
