import { fetch, fetchRawResponse } from '@tomra/client-side-http-client'
import type { HttpError } from '@tomra/client-side-http-client'
import { authentication } from './authentication'

const requestConfig = {
  fetchNewToken: authentication.fetchNewToken,
  getToken: authentication.getToken,
  timeoutInMs: 120000
}

const JSON_HEADERS = {
  'Content-Type': 'application/json'
}

export function authenticatedGet(url: string, returnRawResponse: boolean = false) {
  const options = {
    method: 'GET',
    headers: JSON_HEADERS,
    returnRawResponse
  }

  return returnRawResponse
    ? fetchRawResponse(`${url}`, options, requestConfig)
    : fetch(`${url}`, options, requestConfig)
}

export function authenticatedPost(url: string, body: any, returnRawResponse: boolean = false) {
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: JSON_HEADERS
  }

  return returnRawResponse
    ? fetchRawResponse(`${url}`, options, requestConfig)
    : fetch(`${url}`, options, requestConfig)
}

export function authenticatedPut(url: string, body: any) {
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: JSON_HEADERS
  }

  return fetch(`${url}`, options, requestConfig)
}

export function authenticatedDelete(url: string) {
  const options = {
    method: 'DELETE'
  }

  return fetch(`${url}`, options, requestConfig)
}

export const defaultHttpErrorHandler = (maybeHttpError: HttpError | any) => {
  if (maybeHttpError?.body?.message) {
    throw new Error(maybeHttpError?.body?.message)
  }

  throw new Error('Unknown error: ' + maybeHttpError?.message, maybeHttpError)
}
