import { useCallback, useEffect, useState } from 'react'
import { authenticatedGet } from './httpClient'

export type Success<T> = {
  type: 'success'
  value: T
}

type Reloading<T> = {
  type: 'reloading'
  value: T
}

type Error = {
  type: 'error'
  error: string
}

type Loading = {
  type: 'loading'
}

export type RemoteData<T> = Success<T> | Reloading<T> | Error | Loading

export const success = <T>(value: T): Success<T> => ({
  type: 'success',
  value
})

export const reloading = <T>(value: T): Reloading<T> => ({
  type: 'reloading',
  value
})

export const error = (errorMessage: string): Error => ({
  type: 'error',
  error: errorMessage
})

export const loading: Loading = {
  type: 'loading'
}

// Use Callback is required to do on the incoming transform function (if it is defined as a lambda in the render function)
// before passing into useRemoteData. This is to avoid retriggering the effect on every render
// (Which will happen if the function is defined in the render)
export function useRemoteData<T>(
  resourceUrl: string,
  transform?: (original: { data: unknown }) => T
): { data: RemoteData<T>; refresh: () => void } {
  const [[requestAge, prevData], setRequestAge] = useState<[Date, RemoteData<T>]>([new Date(), loading])
  const [data, setData] = useState<RemoteData<T>>(loading)

  useEffect(() => {
    let mounted = true

    if (prevData.type === 'loading' || prevData.type === 'error') {
      setData(loading)
    } else {
      setData(reloading(prevData.value))
    }

    authenticatedGet(resourceUrl)
      .run()
      .then((value: unknown) => {
        if (mounted) {
          if (isDataKeyed(value)) {
            const data = (value as any).data
            setData(transform ? success(transform({ data })) : success(data as T))
          } else {
            setData(transform ? success(transform({ data: value })) : success(value as T))
          }
        }
      })
      .catch((err: any) => {
        if (mounted) setData(error(err?.message || err?.toString() || err))
      })

    return () => {
      mounted = false
    }
  }, [resourceUrl, requestAge, prevData, transform])

  return {
    data,
    refresh: () => setRequestAge([new Date(), data])
  }
}

function isDataKeyed(value: unknown): boolean {
  return typeof value === 'object' && (value as any)['data'] !== undefined && Object.keys(value as any).length === 1
}

export function useAutoRefresh(refreshFn: () => void, intervalMillis: number) {
  const refresh = useCallback(() => {
    refreshFn()
  }, [refreshFn])
  useEffect(() => {
    let previousHidden = false
    let previousRefresh = new Date()
    const id = setInterval(() => {
      if (previousHidden !== document.hidden) {
        if (!document.hidden) {
          refresh()
          previousRefresh = new Date()
        }
        previousHidden = document.hidden
      }

      if (!document.hidden) {
        if (new Date().getTime() - previousRefresh.getTime() > intervalMillis) {
          refresh()
          previousRefresh = new Date()
        }
      }
    }, 1000)

    return () => {
      clearInterval(id)
    }
  }, [intervalMillis, refresh])
}
