import React, { FunctionComponent } from 'react'
import { ArrowRightIcon, ArrowLeftIcon } from './icons'

type Props = {
  currentPage: number
  numOfPages: number
  numOfItems?: number
  recordsPerPage?: number
  onPageUpdate: Function
  onRecordsPerPageChange?: Function
  recordsPerPageOptions?: Array<number>
}

export const TableFooter: FunctionComponent<Props> = ({
  currentPage,
  numOfPages,
  numOfItems,
  onRecordsPerPageChange,
  recordsPerPage,
  recordsPerPageOptions,
  onPageUpdate
}) => {
  const pageOptionsWithDefault = Array.isArray(recordsPerPageOptions) ? recordsPerPageOptions : [30, 60, 100]

  const nextPage = () => {
    if (currentPage !== numOfPages) {
      const newPage = currentPage + 1
      onPageUpdate(newPage)
    }
  }

  const previousPage = () => {
    if (currentPage !== 1) {
      const newPage = currentPage - 1
      onPageUpdate(newPage)
    }
  }

  return (
    <div className="p-md grid grid-cols-12 gap-x-md items-center">
      <div className="flex items-center gap-sm col-span-4">
        <button className="btn btn-icon" onClick={previousPage} disabled={currentPage === 1}>
          <ArrowLeftIcon />
        </button>
        <span>
          Page {currentPage} of {numOfPages}
        </span>
        <button className="btn btn-icon" onClick={nextPage} disabled={currentPage >= numOfPages}>
          <ArrowRightIcon />
        </button>
      </div>

      <span className="mx-lg col-span-4 text-center">Total items: {numOfItems !== undefined ? numOfItems : 0} </span>

      {recordsPerPage && onRecordsPerPageChange && (
        <React.Fragment>
          <label htmlFor="recordPerPage" className="col-span-3 text-right">
            Records per page:
          </label>
          <select
            id="recordPerPage"
            className="select col-span-1"
            value={recordsPerPage}
            onChange={({ target }) => onRecordsPerPageChange(target.value)}
          >
            {pageOptionsWithDefault.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </React.Fragment>
      )}
    </div>
  )
}
