import { useState } from 'react'
import { error, loading, RemoteData, success } from './RemoteData'
import { UserService } from '../services/UserService'

export const useUserSearch = () => {
  const [searchResult, setSearchResult] = useState<RemoteData<FoundUser[]>>(success([]))

  const search = async (searchTerm: string): Promise<void> => {
    setSearchResult(loading)
    try {
      const foundUsers = (await UserService.searchForUsers(searchTerm)) || []
      setSearchResult(success(foundUsers))
    } catch (err: any) {
      setSearchResult(error(err.message || err.toString()))
    }
  }

  const resetSearch = () => {
    setSearchResult(success([]))
  }

  return {
    searchResult,
    search,
    resetSearch
  }
}

export type FoundUser = {
  id: string
  email: string
  firstName: string
  lastName: string
  numOfLicenses: number
}
