import React, { useState } from 'react'
import { Checkbox } from './Checkbox'
import styled from 'styled-components'
import { isAncestorOf, PathScope, StructureNode } from '../../../services/Dtos'
import { ArrowDownIcon, ArrowRightIcon } from '../../icons'

type CheckboxState = 'checked' | 'indeterminate' | 'unchecked'

type NodeProps = {
  node: StructureNode
  scopes: PathScope[]
  isParentAllowed: boolean
  isReadOnly: boolean
  deny: (node: StructureNode) => void
  allow: (node: StructureNode) => void
}

const IconContainer = styled.div`
  cursor: pointer;
  display: inline-block;
`

function getCheckBoxState(scopes: PathScope[], node: StructureNode, isAllowed: boolean): CheckboxState {
  const isChildDenied = scopes.some(scope =>
    scope.exclude.some(exclude => exclude !== node && exclude.path.includes(`${node.type}:${node.id}`))
  )
  const isChildAllowed = scopes.some(scope =>
    scope.include.some(exclude => exclude !== node && exclude.path.includes(`${node.type}:${node.id}`))
  )

  if (isChildDenied || isChildAllowed) {
    return 'indeterminate'
  }

  if (isAllowed) {
    return 'checked'
  }

  return 'unchecked'
}

const IconPlaceHolder = styled.div`
  display: inline-block;
  height: 28px;
  width: 28px;
`

const TreeNode = ({ node, scopes, isParentAllowed, isReadOnly, deny, allow }: NodeProps) => {
  const [isOpen, setIsOpen] = useState(node.id === '' && node.children.length > 0)

  /*
  - A node can be one of (country, org, location, installation)
  - We call it "included" if a node is explicitly listed in the scope's "include" list
  - We call it "excluded" if a node is explicitly listed in the scope's "exclude" list
  - We call it "allowed" when a node is covered by the scope
  - We call it "denied" when a node is not covered by the scope
  - Nodes are _implicitly_ denied by default (if it's not mentioned in include or exclude, it is denied)
  - A node is allowed either by it being included or any of it's ancestors being included
  - A node is denied either if it's not allowed as described above, or if it's _explicitly_ denied by it or any of it's ancestors being excluded
  - If a node is included, by default all it's descendants are also allowed
  - If a node is excluded, all it's descendants are also denied
  - Children of an allowed node can be denied by adding an exclude (a node can be excluded if an ancestor is included)
  - Children of an _explicitly_ denied (i.e. excluded) node can _not_ be allowed by adding an include (a node can _not_ be included if an ancestor is excluded)
   */

  const isExplicitlyDenied = scopes.some(scope => scope.exclude.some(exclude => exclude === node))
  const isExplicitlyAllowed = scopes.some(scope => scope.include.some(include => include === node))
  const isAllowed = !isExplicitlyDenied && (isExplicitlyAllowed || isParentAllowed)
  const isAncestorExplicitlyDenied = scopes.some(scope =>
    scope.exclude.some(excluded => isAncestorOf(excluded, node) && node !== excluded)
  )
  const checkboxState: CheckboxState = getCheckBoxState(scopes, node, isAllowed)
  const display = node.display

  return (
    <li>
      {node.children.length > 0 ? (
        <IconContainer onClick={() => setIsOpen(!isOpen)} role="expander">
          {isOpen ? <ArrowDownIcon size="28" /> : <ArrowRightIcon size="28" />}
        </IconContainer>
      ) : (
        <IconPlaceHolder />
      )}

      {node.type === 'region' || node.id === '' ? null : (
        <Checkbox
          state={checkboxState}
          disabled={node.id === '' || isAncestorExplicitlyDenied}
          onChange={() => {
            if (!isReadOnly) {
              if (isAllowed) {
                deny(node)
              } else {
                allow(node)
              }
            }
          }}
        />
      )}

      <span>{display}</span>
      {isOpen ? (
        <ul className="pl-md">
          {node.children
            .sort((a, b) => (a.display + a.id > b.display + b.id ? 1 : -1))
            .map(child => (
              <TreeNode
                key={child.id}
                node={child}
                scopes={scopes}
                isParentAllowed={isAllowed}
                isReadOnly={isReadOnly}
                deny={deny}
                allow={allow}
              />
            ))}
        </ul>
      ) : null}
    </li>
  )
}

type TreeViewerProps = {
  data: StructureNode[]
  scopes: PathScope[]
  isReadOnly: boolean
  deny: (node: StructureNode) => void
  allow: (node: StructureNode) => void
}

export const TreeViewer = ({ data, scopes, isReadOnly, deny, allow }: TreeViewerProps) => {
  return (
    <ul>
      {data.map(node => (
        <TreeNode
          key={node.id}
          node={node}
          scopes={scopes}
          isParentAllowed={false}
          isReadOnly={isReadOnly}
          allow={allow}
          deny={deny}
        />
      ))}
    </ul>
  )
}
