import React, { useState } from 'react'
import { FullLicense, ProductWithRolesDto } from '../../../services/Dtos'
import { LicenseService } from '../../../services/LicenseService'
import { DeleteIcon, EditIcon, SaveIcon } from '../../../components/icons'
import { createPortal } from 'react-dom'
import { ConfirmActionDialog } from '../ConfirmActionDialog'

type Props = {
  fullLicense: FullLicense
  productWithRoles: ProductWithRolesDto[]
  onSaved: () => void
}

export const ViewUsersTable = ({ fullLicense, productWithRoles, onSaved }: Props) => {
  const [userBeingEdited, setUserBeingEdited] = useState('')
  const filteredProducts = productWithRoles.find(p => p.product)
  const availableRoles = filteredProducts ? filteredProducts.roles : []
  const [role, setRole] = useState('')
  const [viewConfirmDisableId, setViewConfirmDisableId] = useState('')

  return (
    <div className="pb-md">
      <h1 className="text-xl mb-md p-md">Active users for {fullLicense.productName} license</h1>

      <table className="table" style={{ maxWidth: '70vw' }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>E-mail</th>
            <th>Role</th>
            <th colSpan={2}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {fullLicense.users.map(licenseForUser => {
            return (
              <tr key={licenseForUser.userId + licenseForUser.role}>
                <td>{licenseForUser.firstName + ' ' + licenseForUser.lastName}</td>
                <td>{licenseForUser.email}</td>
                <td>
                  {userBeingEdited === licenseForUser.userId ? (
                    <select
                      aria-label="Role"
                      className="select"
                      onChange={({ target }) => setRole(target.value)}
                      value={role}
                    >
                      {availableRoles.map(availableRole => (
                        <option key={availableRole} value={availableRole}>
                          {availableRole}
                        </option>
                      ))}
                    </select>
                  ) : (
                    licenseForUser.role
                  )}
                </td>
                <td>
                  {userBeingEdited === licenseForUser.userId ? (
                    <button
                      aria-label="Save role"
                      className="btn btn-icon"
                      onClick={() => {
                        LicenseService.updateUserRoleForLicense(licenseForUser.id, role).then(() => {
                          setUserBeingEdited('')
                          onSaved()
                        })
                      }}
                    >
                      <SaveIcon color="var(--colors-blue-dark)" />
                    </button>
                  ) : (
                    <button
                      aria-label="Edit role"
                      onClick={() => {
                        setUserBeingEdited(licenseForUser.userId)
                        setRole(licenseForUser.role)
                      }}
                    >
                      <EditIcon color="var(--colors-blue-dark)" />
                    </button>
                  )}
                </td>
                <td>
                  <button
                    aria-label="Disable license for user"
                    className="btn btn-icon"
                    onClick={() => setViewConfirmDisableId(licenseForUser.id)}
                  >
                    <DeleteIcon color="var(--colors-red)" />
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {viewConfirmDisableId &&
        createPortal(
          <ConfirmActionDialog
            message="This will disable the access to this license for this user. Are you sure you want to continue?"
            onConfirm={() => LicenseService.disableLicenseForUser(viewConfirmDisableId).then(onSaved)}
            onDone={() => setViewConfirmDisableId('')}
          />,
          document.getElementById('modal-root')!
        )}
    </div>
  )
}
