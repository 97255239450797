import React, { FunctionComponent, useEffect } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  onClose?: () => void
  closeButtonColor?: string
  shouldCloseOnOverlayClick?: boolean
  overflows?: boolean
  children?: React.ReactNode
}

export const Dialog: FunctionComponent<Props> = ({
  children,
  onClose,
  shouldCloseOnOverlayClick = true,
  overflows = true
}) => {
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (onClose && event.key === 'Escape') {
        event.stopPropagation()
        onClose()
      }
    }

    window.addEventListener('keydown', handleEscapeKey)

    return () => window.removeEventListener('keydown', handleEscapeKey)
  }, [onClose])

  return createPortal(
    <div
      onClick={shouldCloseOnOverlayClick ? onClose : () => {}}
      className="fixed top-0 left-0 flex w-full h-full justify-center z-10"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
    >
      <div
        className="relative z-10"
        style={{ ...(!overflows && { overflowY: 'auto' }), maxHeight: '80vh', top: '6rem' }}
        onClick={evt => evt.stopPropagation()}
      >
        {children}
      </div>
    </div>,
    document.getElementById('root') as HTMLDivElement
  )
}
