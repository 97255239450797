import React, { useState } from 'react'
import styled from 'styled-components'
import { SortableTh } from './SortableTh'

type Order = 'asc' | 'desc'

export function useTableSorting<T>(
  columnStringExtractorMap: { [key: string]: (candidate: T) => string },
  defaultColumn?: string
) {
  const [[column, direction], setSorting] = useState<[string, Order]>([defaultColumn ?? '', 'asc'])

  const sort = (array: T[]): T[] => {
    const extractor = columnStringExtractorMap[column]
    if (extractor) {
      return array.sort((a, b) => {
        const inverter = direction === 'desc' ? 1 : -1
        return (extractor(a) > extractor(b) ? -1 : 1) * inverter
      })
    }
    return array
  }

  return {
    currentSort: {
      column,
      direction,
      setSorting
    },
    sort
  }
}

type SortableHeaderProps = {
  name: string
  currentSort: {
    column: string
    direction: 'asc' | 'desc'
    setSorting: (value: [string, Order]) => void
  }
  minWidth?: string
}

const MinWidthSortableTh = styled(SortableTh)<{ minWidth?: string }>`
  ${props => (props.minWidth ? 'min-width: ' + props.minWidth + ';' : '')}
`

export const SortableHeader = ({ name, currentSort, minWidth }: SortableHeaderProps) => {
  return (
    <MinWidthSortableTh
      minWidth={minWidth}
      onClick={(order: Order, name: string) => {
        currentSort.setSorting([name, order])
      }}
      name={name}
      order={currentSort.column === name ? currentSort.direction : null}
    >
      {name}
    </MinWidthSortableTh>
  )
}
