import React from 'react'

type Props = {
  children?: React.ReactNode
}
export const ProductLicenseTable = ({ children }: Props) => {
  return (
    <table className="table table-auto">
      <thead>
        <tr>
          <th />
          <th>Business Unit</th>
          <th>Account</th>
          <th>Number of users</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  )
}
