import FastClick from 'fastclick'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { authentication } from './lib/authentication'
import { App } from './App'
import { logError } from './lib/logError'
import '@tomra/design-system/src/config/tailwind.config.css'

const mountNode = document.getElementById('root')
if (!mountNode) {
  throw new Error('Could not get #root element')
}
const root = createRoot(mountNode!)

window.addEventListener('load', () => {
  ;(FastClick as any).attach(document.body)
})

authentication.init().then(
  () => {
    root.render(<App />)
  },
  (err: any) => {
    if (err === 'Unauthenticated') {
      authentication.login()
      return
    }
    err
      ? logError(new Error('Authentication failed'), err)
      : logError(new Error('Authentication failed'), new Error('Unknown error'))

    root.render(<div className="alert alert-danger">Wooopsie, authentication failed for some reason!</div>)
  }
)
