import { API_HOST } from '../lib/environment'
import { saveAs } from 'file-saver'
import { fetchRawResponse } from '@tomra/client-side-http-client'
import { authentication } from '../lib/authentication'

const requestConfig = {
  fetchNewToken: authentication.fetchNewToken,
  getToken: authentication.getToken
}

const EXCEL_HEADERS = {
  Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export function fetchLatestReportForBu(businessUnitId: string): Promise<void> {
  const options = {
    method: 'GET',
    headers: EXCEL_HEADERS
  }

  return fetchRawResponse(`${API_HOST}/report/current/${businessUnitId}`, options, requestConfig)
    .run()
    .then(async (resp: Response) => {
      if (resp.ok) {
        const blob = await resp.blob()
        const disposition = resp.headers.get('Content-Disposition') || ''
        saveAs(blob, fileNameFromDisposition(disposition, `latest-${businessUnitId}.xlsx`))
      }
    })
}

export function downloadReportId(reportId: number): Promise<void> {
  const options = {
    method: 'GET',
    headers: EXCEL_HEADERS
  }

  return fetchRawResponse(`${API_HOST}/report/download/${reportId}`, options, requestConfig)
    .run()
    .then(async (resp: Response) => {
      const blob = await resp.blob()
      const disposition = resp.headers.get('content-disposition') || ''
      saveAs(blob, fileNameFromDisposition(disposition, `report-${reportId}.xlsx`))
    })
}

function fileNameFromDisposition(disposition: string, fallbackFileName: string) {
  if (disposition.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    var matches = filenameRegex.exec(disposition)
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '')
    }
  }
  return fallbackFileName
}
