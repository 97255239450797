import {
  authenticatedDelete,
  authenticatedGet,
  authenticatedPost,
  authenticatedPut,
  defaultHttpErrorHandler
} from '../lib/httpClient'

import { API_HOST } from '../lib/environment'
import {
  AccountCreationResponse,
  AccountDto,
  BusinessUnit,
  CreateAccountRequestData,
  FullAccountDto,
  Scope
} from './Dtos'

export function fetchAccounts(): Promise<AccountDto[]> {
  return authenticatedGet(`${API_HOST}/account`)
    .run()
    .then((resp: { accounts: AccountDto[] }) => resp.accounts)
}

export function fetchAccountsWithoutActiveInstallations(): Promise<AccountDto[]> {
  return authenticatedGet(`${API_HOST}/account/no_active_installations`)
    .run()
    .then((resp: { accounts: AccountDto[] }) => resp.accounts)
}

export function fetchAccount(accountId: string): Promise<FullAccountDto> {
  return authenticatedGet(`${API_HOST}/account/${accountId}?includeInactiveLicenses=true`)
    .run()
    .then((resp: FullAccountDto) => resp)
}

export function createAccount(account: CreateAccountRequestData): Promise<AccountCreationResponse> {
  return authenticatedPost(`${API_HOST}/account`, account).run().catch(defaultHttpErrorHandler)
}

export function editAccount(
  accountId: string,
  values: { name?: string; description?: string; businessUnitId?: string; accountState?: 'ACTIVE' }
): Promise<AccountCreationResponse> {
  return authenticatedPut(`${API_HOST}/account/${accountId}`, { ...values })
    .run()
    .catch(defaultHttpErrorHandler)
}

export function configurePolicyForAccount(accountId: string, scopes?: Scope[]) {
  return authenticatedPost(`${API_HOST}/account/${accountId}/scopes`, { scopes }).run()
}

export function fetchBusinessUnits(): Promise<BusinessUnit[]> {
  return authenticatedGet(`${API_HOST}/businessunit`).run()
}

export function deactivateAccount(accountId: string): Promise<void> {
  return authenticatedDelete(`${API_HOST}/account/${accountId}`).run()
}

export function deleteAccount(accountId: string): Promise<void> {
  return authenticatedDelete(`${API_HOST}/account/deactivated/${accountId}`).run()
}
