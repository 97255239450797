import React, { useState } from 'react'
import { Link, useParams, useRouteLoaderData } from 'react-router-dom'
import * as RemoteData from '../../lib/RemoteData'
import { colors } from '../../styles'
import { downloadReportId, fetchLatestReportForBu } from '../../services/ReportService'
import { BusinessUnit, ReportEntry } from '../../services/Dtos'
import { API_HOST } from '../../lib/environment'
import { FormattedDate, FormattedDateTime } from '../../components/FormattedDateTime'
import { ArrowLeftIcon } from '../../components/icons'

export const BusinessUnitDetailsPage = () => {
  const { id } = useParams<{ id: string }>()
  const businessUnits = useRouteLoaderData('withBusinessUnits') as BusinessUnit[]
  const [cooldown, setCooldown] = useState(false)

  const { data: reportEntries } = RemoteData.useRemoteData<ReportEntry[]>(`${API_HOST}/report/overview/${id}`)

  if (reportEntries.type === 'error') {
    return (
      <div className="centerAbsolute">
        <div className="alert alert-danger">Uh-oh.. Failed to load business unit</div>
      </div>
    )
  }

  if (reportEntries.type === 'loading') {
    return <div className="loadingSpinner" />
  }

  const businessUnit = businessUnits.find(bu => bu.id === id) ?? {
    id,
    name: 'UNKNOWN',
    countries: [],
    ifsNumber: ''
  }

  return (
    <React.Fragment>
      <div className="flex items-center mb-md">
        <Link to="/accounts">
          <ArrowLeftIcon color={colors.PRIMARY} />
        </Link>

        <h1 className="text-xl">Business Unit Reports</h1>
      </div>

      <div className="card p-md">
        <div className="flex justify-between py-md pl-md pr-xl">
          <h2 className="text-lg">
            {businessUnit?.ifsNumber ? `${businessUnit.ifsNumber}: ${businessUnit.name}` : `${businessUnit.name}`}
          </h2>

          <button
            type="button"
            className="btn"
            disabled={cooldown}
            onClick={() => {
              setCooldown(true)
              fetchLatestReportForBu(businessUnit?.id || '').finally(() => {
                setCooldown(false)
              })
            }}
          >
            Download Staged Report
          </button>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>Report Timestamp</th>
              <th>Invoice Rows</th>
              <th>Executed</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {reportEntries.value.map(reportEntry => {
              return (
                <tr key={reportEntry.id}>
                  <td>
                    <FormattedDate timestamp={reportEntry.reportTimestamp} />
                  </td>
                  <td>{reportEntry.invoiceRows}</td>
                  <td>
                    <FormattedDateTime timestamp={reportEntry.created} />
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-success"
                      disabled={cooldown}
                      onClick={() => {
                        setCooldown(true)
                        downloadReportId(reportEntry.id).finally(() => {
                          setCooldown(false)
                        })
                      }}
                    >
                      Download
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}
