import React from 'react'

type Props = {
  timestamp: string | Date
}

export const FormattedDate = ({ timestamp }: Props) => {
  const date = timestamp instanceof Date ? timestamp : new Date(timestamp)
  return (
    <React.Fragment>
      {new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }).format(date)}
    </React.Fragment>
  )
}

export const FormattedDateTime = ({ timestamp }: Props) => {
  const date = timestamp instanceof Date ? timestamp : new Date(timestamp)
  return (
    <React.Fragment>
      {new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(date)}
    </React.Fragment>
  )
}
