import React, { useState } from 'react'
import { CardDialog } from '../../components/CardDialog'

type Props = {
  message: string
  onConfirm: () => Promise<void>
  onDone: () => void
}

export const ConfirmActionDialog = ({ message, onConfirm, onDone }: Props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  return (
    <CardDialog onClose={onDone}>
      <div className="flex flex-col gap-md">
        <p>{message}</p>
        {error ? <p style={{ color: 'red' }}>{error.toString()}</p> : null}

        <button
          className="btn btn-primary-dark self-end"
          disabled={loading}
          onClick={() => {
            setLoading(true)
            onConfirm()
              .then(onDone)
              .catch(err => {
                setError(err)
              })
          }}
        >
          {loading && <div className="loadingSpinner" />} Confirm
        </button>
      </div>
    </CardDialog>
  )
}
