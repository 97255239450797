import React from 'react'
import { Link, Outlet, useRouteError, useSearchParams } from 'react-router-dom'
import { ReactComponent as ArrowLeftIcon } from '@tomra/design-system/src/config/icons/arrow-left.svg'

export const AccountDetailsPage = () => {
  const error = useRouteError() as { message: string }
  let [searchParams] = useSearchParams()
  const saved = searchParams.get('saved')

  return (
    <div className="px-md">
      <Link to="/accounts" className="text-blue">
        <ArrowLeftIcon className="inline-block" width="1rem" height="1rem" color="var(--colors-blue)" />
        <span className="text-md align-middle">Accounts</span>
      </Link>
      <div>
        {saved && <div className="alert alert-success mb-md">Account was successfully saved</div>}
        {error && <div className="alert alert-danger mb-md">{error.message}</div>}
        <Outlet />
      </div>
    </div>
  )
}
