import React, { useState } from 'react'

type Props = {
  title: string
  initialDate?: Date
  onComplete: (date: Date) => Promise<void>
  allowClearing?: boolean
}

const CLEAR_DATE = new Date('')

export const SpecifyDateForm = ({ title, initialDate, onComplete, allowClearing = false }: Props) => {
  const [date, setDate] = useState<Date>(initialDate ?? new Date())
  const [error, setError] = useState<string | undefined>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  return (
    <div className="p-lg flex flex-col gap-md">
      <h2 className="text-xl">{title}</h2>

      <div className="mb-sm">
        <label htmlFor="date" className="mb-xs block">
          Date
        </label>
        <input
          id="date"
          type="date"
          className="datepicker"
          value={(isValidDate(date, false) && date.toISOString().substring(0, 10)) || ''}
          onChange={({ target }) => {
            setDate(new Date(target.value))
          }}
        />
      </div>

      <div className="flex gap-md self-end">
        {allowClearing && (
          <button className="btn" onClick={() => setDate(CLEAR_DATE)}>
            Clear
          </button>
        )}
        <button
          className="btn btn-primary-dark active:bg-blue-light"
          disabled={isSubmitting || !isValidDate(date, allowClearing)}
          onClick={() => {
            setIsSubmitting(true)
            onComplete(date).catch(error => {
              setError(error.message)
              setIsSubmitting(false)
            })
          }}
        >
          {isSubmitting ? <div className="loadingSpinner" /> : 'Save'}
        </button>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  )
}

function isValidDate(date: Date, allowClearing: boolean): boolean {
  return allowClearing || !isNaN(date.getTime())
}
