import React, { useState } from 'react'
import { TreeViewer } from './TreeBrowser'
import { ScopeSummary } from './ScopeSummary'
import { isAncestorOf, PathScope, StructureNode } from '../../../services/Dtos'

type Props = {
  hierarchyData: StructureNode[]
  onComplete: (scopes: PathScope[]) => void
  initialScopes?: PathScope[]
  readOnly?: boolean
  isSubmitting: boolean
}

export function allowNodeInScopes(pathScopes: PathScope[], node: StructureNode): PathScope[] {
  const scopeWhereExcluded = pathScopes.find(scope => scope.exclude.some(excluded => excluded === node))
  const existingChild = pathScopes.find(scope => scope.include.some(included => isAncestorOf(node, included)))
  const withoutChildScopes = pathScopes.filter(
    scope =>
      !scope.include.some(included => isAncestorOf(node, included)) &&
      !scope.exclude.some(excluded => excluded === node)
  )

  return [
    ...withoutChildScopes,
    scopeWhereExcluded
      ? {
          include: scopeWhereExcluded.include,
          exclude: scopeWhereExcluded.exclude.filter(excluded => excluded !== node)
        }
      : {
          include: [node],
          exclude: [...(existingChild?.exclude ?? [])]
        }
  ]
}

export function denyNodeInScopes(pathScopes: PathScope[], node: StructureNode) {
  const ancestorScope = pathScopes.find(scope =>
    scope.include.some(included => node !== included && isAncestorOf(included, node))
  )
  const withoutRelevantOrRemovedScope = pathScopes.filter(
    scope => !scope.include.some(included => isAncestorOf(included, node) || node === included)
  )
  return [
    ...withoutRelevantOrRemovedScope,
    ...(ancestorScope ? [{ include: ancestorScope.include, exclude: [...ancestorScope.exclude, node] }] : [])
  ]
}

export const MasterdataAssignment = ({ onComplete, hierarchyData, initialScopes, readOnly, isSubmitting }: Props) => {
  const [pathScopes, setPathScopes] = useState<PathScope[]>(initialScopes ?? [])

  const allow = (node: StructureNode) => {
    setPathScopes(allowNodeInScopes(pathScopes, node))
  }

  const deny = (node: StructureNode) => {
    setPathScopes(denyNodeInScopes(pathScopes, node))
  }

  return (
    <div className="flex flex-col gap-md p-md" style={{ width: '50vw', minHeight: '25rem' }}>
      <div>
        <h1 className="text-xl">Define scopes for access to installations</h1>
      </div>
      <div>
        {!readOnly && (
          <button className="btn btn-primary-dark" onClick={() => onComplete(pathScopes)} disabled={isSubmitting}>
            Submit
          </button>
        )}
      </div>
      <div className="flex">
        <div className="flex-1">
          <TreeViewer
            data={hierarchyData}
            scopes={pathScopes}
            isReadOnly={readOnly || false}
            allow={allow}
            deny={deny}
          />
        </div>

        <div className="flex-1">
          <ScopeSummary scopes={pathScopes} />
        </div>
      </div>
    </div>
  )
}
