import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles'

const Checked = styled.div`
  display: inline;
  content: '';
  position: absolute;
  width: 9px;
  height: 6px;
  background: transparent;
  top: 6px;
  left: 6px;
  border: 3px solid white;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
`

const Indeterminate = styled.div`
  display: inline;
  content: '';
  position: absolute;
  width: 9px;
  background: transparent;
  top: 9px;
  left: 6px;
  border: 3px solid white;
  border-top: none;
  border-right: none;
  transform: rotate(0deg);
`

type BoxProps = { state: 'checked' | 'indeterminate' | 'unchecked'; disabled: boolean }

const Box = styled.div.attrs<BoxProps>((props: any) => ({ 'aria-label': props.state }))`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: ${(props: BoxProps) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${props => (props.disabled ? '#CCC' : props.state === 'unchecked' ? 'initial' : colors.PRIMARY)};
  border: ${props => (props.state === 'unchecked' || props.disabled ? '1px solid ' + colors.SECONDARY_TEXT : '')};
  border-radius: 4px;
  left: -4px;
`

type Props = {
  state: 'checked' | 'indeterminate' | 'unchecked'
  onChange: () => void
  disabled: boolean
  title?: string
}

export const Checkbox = ({ state, onChange, disabled, title }: Props) => {
  return (
    <Box
      state={state}
      disabled={disabled}
      onClick={() => (disabled ? null : onChange())}
      role={'checkbox'}
      title={title}
    >
      {disabled ? null : state === 'checked' ? <Checked /> : state === 'indeterminate' ? <Indeterminate /> : null}
    </Box>
  )
}
