import React, { FunctionComponent } from 'react'
import { AnimateHeight } from '../../../components/AnimateHeight'

type Props = {
  isActive: boolean
  users: { id: string; email: string; firstName: string; lastName: string; role: string }[]
}

export const UsersForLicense: FunctionComponent<Props> = ({ isActive, users }) => {
  return (
    <tr>
      <td colSpan={5} className="p-0">
        <AnimateHeight animate={isActive}>
          <table className="table table-auto">
            <thead>
              <tr className="bg-wind border-wind">
                <th />
                <th>E-mail</th>
                <th>Role</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={`${user.role}-${user.id}`} className="bg-wind border-wind">
                  <td width="5%" />
                  <td>{user.email || '-'}</td>
                  <td>{user.role}</td>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </AnimateHeight>
      </td>
    </tr>
  )
}
