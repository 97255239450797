import React from 'react'
import { useRemoteData } from '../../lib/RemoteData'
import { GET_USER_DETAILS } from '../../services/UserService'
import { useParams, Link } from 'react-router-dom'
import { UserDetails } from '../../services/Dtos'
import { FormattedDateTime } from '../../components/FormattedDateTime'

export const UserDetailsPage = () => {
  const { id = '' } = useParams<{ id: string }>()
  const { data } = useRemoteData<UserDetails>(GET_USER_DETAILS.replace('{userId}', id || 'bla'))

  if (data.type === 'loading') {
    return (
      <div className="card p-md centerFlex">
        <div className="loadingSpinner" />
      </div>
    )
  }

  if (data.type === 'error') {
    return (
      <div className="centerAbsolute">
        <div className="alert alert-danger">Uh-oh.. Failed to load accounts</div>
      </div>
    )
  }

  return (
    <div className="card">
      <h3 className="m-md inline-block">{`${data.value.user.firstName} ${data.value.user.lastName} (${data.value.user.email})`}</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Account</th>
            <th>Product</th>
            <th>User Role</th>
            <th>Activated</th>
            <th>Expires</th>
          </tr>
        </thead>
        <tbody>
          {data.value.licenses.map((userLicense, index) => {
            return (
              <tr key={index}>
                <td>
                  <Link className="link" to={'/accounts/' + userLicense.accountId}>
                    {userLicense.accountName}
                  </Link>
                </td>
                <td>{userLicense.productName}</td>
                <td>{userLicense.role}</td>
                <td>
                  <FormattedDateTime timestamp={userLicense.activated} />
                </td>
                <td>{userLicense.expires && <FormattedDateTime timestamp={userLicense.expires} />}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
