import React from 'react'
import { colors } from '../../styles'

type SaveIconProps = {
  color?: string
  size?: string
}

export const SaveIcon = (props: SaveIconProps) => (
  <svg
    fill={props.color || colors.SECONDARY_TEXT}
    height={props.size || '36'}
    viewBox="0 0 24 24"
    width={props.size || '36'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
)

export default SaveIcon
