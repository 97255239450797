import React, { useEffect, useState } from 'react'
import { authenticatedGet } from '../../lib/httpClient'
import { API_HOST } from '../../lib/environment'
import { logError } from '../../lib/logError'
import { Link } from 'react-router-dom'

type Product = {
  product: string
  roles: string[]
  disabled: boolean
}

export const ProductsOverviewPage = () => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    setStatus('pending')

    const f = authenticatedGet(`${API_HOST}/products`)

    f.run()
      .then(response => {
        setProducts(response)
        setStatus('idle')
      })
      .catch(error => {
        setStatus('failure')
        logError(new Error('Unable to fetch products'), error)
      })

    return () => f.abort()
  }, [])

  return (
    <div>
      <h1 className="text-xl mb-md">Products</h1>

      {status === 'failure' ? (
        <div className="alert alert-danger">Unable to load products</div>
      ) : status === 'pending' ? (
        <div className="loadingSpinner" />
      ) : (
        <div className="card">
          {products.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Roles</th>
                </tr>
              </thead>
              <tbody>
                {products.map(({ product, roles, disabled }) => (
                  <tr key={product}>
                    <td>
                      <Link className="link" to={`/products/${product}`}>
                        {product} {disabled ? '(Disabled)' : ''}
                      </Link>
                    </td>
                    <td>{roles.join(', ')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="p-md">No available products</div>
          )}
        </div>
      )}
    </div>
  )
}
