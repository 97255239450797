import React from 'react'
import { AccountDto, BusinessUnit } from '../../services/Dtos'
import { FormattedDateTime } from '../../components/FormattedDateTime'
import { Link } from 'react-router-dom'

type Props = {
  account: AccountDto
  businessUnits: BusinessUnit[]
}

export const AccountRow = ({ account, businessUnits }: Props) => {
  const buName =
    businessUnits.find(bu => bu.id === account.businessUnitId)?.name ?? 'Unknown ID: ' + account.businessUnitId
  return (
    <tr>
      <td>
        <Link className="text-blue font-bold" to={'/accounts/' + account.id}>
          {account.name}
        </Link>
      </td>
      <td>{account.externalId}</td>
      <td>{account.description ?? ''}</td>
      <td>
        <Link className="text-blue font-bold" to={'/businessunits/' + account.businessUnitId}>
          {buName}
        </Link>
      </td>
      <td>{account.created ? <FormattedDateTime timestamp={account.created} /> : ''}</td>
      <td>{account.authorName}</td>
    </tr>
  )
}
