import React from 'react'
import { colors } from '../../styles'

type LogoProps = {
  size?: string
  color?: string
}

export const Logo = (props: LogoProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={props.size || '36'} viewBox="0 0 33 33" width={props.size || '36'}>
    <path
      d="M16.54,1.49A7.71,7.71,0,1,1,8.83,9.2a7.72,7.72,0,0,1,7.71-7.71m0-1.5a9.21,9.21,0,1,0,9.2,9.21A9.21,9.21,0,0,0,16.54,0Z"
      fill={props.color || colors.YELLOW}
    />
    <path
      d="M24.46,19.46A11.87,11.87,0,0,1,28.37,28v3.51H4.7V28a11.87,11.87,0,0,1,3.91-8.52A13.5,13.5,0,0,0,16.54,22a13.5,13.5,0,0,0,7.92-2.51m0-1.8a.35.35,0,0,0-.22.08,11.91,11.91,0,0,1-7.69,2.73,11.87,11.87,0,0,1-7.69-2.73.4.4,0,0,0-.23-.08.41.41,0,0,0-.18.05A13.08,13.08,0,0,0,3.2,28v4.68a.34.34,0,0,0,.34.33h26a.34.34,0,0,0,.34-.33V28a13.05,13.05,0,0,0-5.24-10.27.37.37,0,0,0-.18-.05Z"
      fill={props.color || colors.YELLOW}
    />
  </svg>
)

export default Logo
