import React, { useCallback, useState } from 'react'
import { BusinessUnit, PathScope, scopesToPathScopes, StructureNode } from '../../services/Dtos'
import { Link, useRevalidator, useRouteError, useRouteLoaderData } from 'react-router-dom'
import { ConfirmActionDialog } from './ConfirmActionDialog'
import { createPortal } from 'react-dom'
import { configurePolicyForAccount, deactivateAccount } from '../../services/AccountsService'
import { ViewLicenseTable } from './license/ViewLicenseTable'
import { CardDialog } from '../../components/CardDialog'
import { AddLicenseForm } from './license/AddLicenseForm'
import { AccessConfigurator } from '../../components/policy/AccessConfigurator'
import { LicenseService } from '../../services/LicenseService'
import { AddUserForm } from './license/AddUserForm'
import { ViewUsersTable } from './license/ViewUsersTable'
import { userIsAdmin } from '../../lib/jwt'
import { AccountAndProducts } from '../../App'
import { RemoteData, useRemoteData } from '../../lib/RemoteData'
import { API_HOST } from '../../lib/environment'
import { ScopesMiniSummary } from '../../components/policy/masterdata-assignment/ScopesMiniSummary'

const deactivated = 'DEACTIVATED'

export type MasterDataAndPathScopes = {
  masterData: StructureNode[]
  pathScopes: PathScope[]
}

export const ViewAccountDetails = () => {
  const error = useRouteError() as { message: string }
  const businessUnits = useRouteLoaderData('withBusinessUnits') as BusinessUnit[]
  const {
    fullAccount: { account, licenses },
    productsWithRoles
  } = useRouteLoaderData('account') as AccountAndProducts
  const { name, externalId, description, businessUnitId, accountState } = account
  const revalidator = useRevalidator()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [addingNewLicense, setAddingNewLicense] = useState(false)
  const [editingScopesForAccount, setEditingScopesForAccount] = useState(false)
  const [viewingScopesForAccount, setViewingScopesForAccount] = useState(false)
  const [editingScopesForLicenseId, setEditingScopesForLicenseId] = useState('')
  const [viewingUsersForLicenseId, setViewingUsersForLicenseId] = useState('')
  const [addingUserForLicenseId, setAddingUserForLicenseId] = useState('')
  const userHasFullResourceAccess = userIsAdmin()

  const hasPolicyWithContent = (account.scopes?.length ?? 0) > 0
  const licenseToEditScope = editingScopesForLicenseId && licenses.find(l => l.id === editingScopesForLicenseId)
  const licenseToViewUsers = viewingUsersForLicenseId && licenses.find(l => l.id === viewingUsersForLicenseId)
  const licenseToAddUser = addingUserForLicenseId && licenses.find(l => l.id === addingUserForLicenseId)
  const businessUnit = businessUnits.find(bu => bu.id === businessUnitId)

  const transform = useCallback(
    (original: { data: unknown }) => {
      let pathScopes = original.data as StructureNode[]
      return {
        masterData: pathScopes,
        pathScopes: scopesToPathScopes(account.scopes ?? [], pathScopes)
      }
    },
    [account]
  )
  const { data: masterDataAndPathScopes }: { data: RemoteData<MasterDataAndPathScopes> } = useRemoteData(
    `${API_HOST}/installation/installation-hierarchy-tree`,
    transform
  )

  if (!businessUnit) {
    return (
      <div className="centerAbsolute">
        <div className="alert alert-danger">
          Uh-oh.. couldn't find business unit {account.businessUnitId} - please try again or report error to support
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      {error && <div className="alert alert-danger">{error.message}</div>}

      <div>
        {accountState === deactivated && <div className="text-red uppercase">Deactivated</div>}
        <h1 className="text-xl my-md">{name}</h1>
        <div className="flex">
          <div className="card p-md leading-loose mr-lg grow">
            <h2 className="text-lg mr-md">Account details</h2>

            <div>
              External id: <strong>{externalId}</strong>
            </div>

            <div>
              <span>Business Unit: </span>
              <Link className="text-blue font-bold" to={`/businessunits/${businessUnitId}`}>
                {businessUnit?.ifsNumber ? businessUnit?.ifsNumber + ' ' + businessUnit?.name : businessUnit?.name}
              </Link>
            </div>

            <div>Description: {description ?? ''}</div>
            <div className="mt-lg flex gap-md">
              <Link className="btn" to="edit">
                Edit account details
              </Link>

              {userHasFullResourceAccess && (
                <button
                  aria-label="Deactivate Account"
                  className="btn btn-primary-danger flex items-center gap-sm"
                  disabled={accountState === deactivated}
                  onClick={() => {
                    setShowConfirmDelete(true)
                  }}
                >
                  Deactivate Account
                </button>
              )}
            </div>
          </div>

          <div className="card py-md px-lg leading-loose grow">
            <h2 className="text-lg mr-md">Access</h2>
            {masterDataAndPathScopes.type !== 'success' ? (
              <div className="loadingSpinner"></div>
            ) : (
              <ScopesMiniSummary scopes={masterDataAndPathScopes.value.pathScopes} />
            )}

            <div className="flex gap-sm mb-md mt-4">
              {hasPolicyWithContent && (
                <button className="btn" onClick={() => setViewingScopesForAccount(true)}>
                  View Details
                </button>
              )}

              <button className="btn" onClick={() => setEditingScopesForAccount(true)}>
                {hasPolicyWithContent ? 'Edit' : 'Configure'}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-lg">
        <div className="p-md flex gap-md items-center justify-between">
          <h3 className="text-lg">Current Licenses</h3>

          <button
            className="btn btn-primary-dark"
            disabled={account.accountState === 'DEACTIVATED'}
            onClick={() => setAddingNewLicense(true)}
          >
            Create New License
          </button>
        </div>

        <ViewLicenseTable
          licenses={licenses}
          businessUnit={businessUnit}
          products={productsWithRoles}
          onEditLicenseScope={setEditingScopesForLicenseId}
          onViewUsersForLicense={setViewingUsersForLicenseId}
          onAddUserForLicense={setAddingUserForLicenseId}
          onSaved={() => {
            revalidator.revalidate()
          }}
        />
      </div>

      {addingNewLicense && (
        <CardDialog onClose={() => setAddingNewLicense(false)}>
          <AddLicenseForm
            accountId={account.id}
            productsWithRoles={productsWithRoles}
            businessUnit={businessUnit}
            onSaved={() => {
              setAddingNewLicense(false)
              revalidator.revalidate()
            }}
          />
        </CardDialog>
      )}

      {viewingScopesForAccount && (
        <CardDialog onClose={() => setViewingScopesForAccount(false)}>
          {masterDataAndPathScopes.type !== 'success' ? (
            <div className="loadingSpinner text-xl" />
          ) : (
            <AccessConfigurator
              pathScopes={masterDataAndPathScopes.value.pathScopes}
              masterDataHierarchy={masterDataAndPathScopes.value.masterData}
              saveFunction={_ => Promise.reject('Read only view')}
              readOnly={true}
              onComplete={() => setViewingScopesForAccount(false)}
            />
          )}
        </CardDialog>
      )}

      {editingScopesForAccount && (
        <CardDialog onClose={() => setEditingScopesForAccount(false)}>
          {masterDataAndPathScopes.type !== 'success' ? (
            <div className="loadingSpinner text-xl" />
          ) : (
            <AccessConfigurator
              pathScopes={masterDataAndPathScopes.value.pathScopes}
              masterDataHierarchy={masterDataAndPathScopes.value.masterData}
              saveFunction={configurePolicyForAccount.bind(null, account.id)}
              readOnly={false}
              onComplete={() => {
                setEditingScopesForAccount(false)
                revalidator.revalidate()
              }}
            />
          )}
        </CardDialog>
      )}

      {licenseToEditScope && (
        <CardDialog onClose={() => setEditingScopesForLicenseId('')}>
          {masterDataAndPathScopes.type !== 'success' ? (
            <div className="loadingSpinner text-xl" />
          ) : (
            <AccessConfigurator
              pathScopes={
                licenseToEditScope.scopes
                  ? scopesToPathScopes(licenseToEditScope.scopes, masterDataAndPathScopes.value.masterData)
                  : []
              }
              masterDataHierarchy={masterDataAndPathScopes.value.masterData}
              saveFunction={LicenseService.configurePolicyForLicense.bind(null, editingScopesForLicenseId)}
              onComplete={() => {
                setEditingScopesForLicenseId('')
                revalidator.revalidate()
              }}
              readOnly={false}
            />
          )}
        </CardDialog>
      )}

      {licenseToAddUser && (
        <CardDialog
          onClose={() => {
            setAddingUserForLicenseId('')
          }}
        >
          <AddUserForm
            productsWithRoles={productsWithRoles}
            onSaved={() => {
              setAddingUserForLicenseId('')
              revalidator.revalidate()
            }}
            license={licenseToAddUser}
          />
        </CardDialog>
      )}

      {licenseToViewUsers && (
        <CardDialog
          onClose={() => {
            setViewingUsersForLicenseId('')
          }}
        >
          <ViewUsersTable
            fullLicense={licenseToViewUsers}
            productWithRoles={productsWithRoles.filter(p => p.product === licenseToViewUsers.productName)}
            onSaved={() => {
              revalidator.revalidate()
            }}
          />
        </CardDialog>
      )}
      {showConfirmDelete &&
        createPortal(
          <ConfirmActionDialog
            message="This will deactivate the account and all user access and licenses for this account. Are you sure you want to continue?"
            onConfirm={() => {
              return deactivateAccount(account.id).then(() => {
                revalidator.revalidate()
              })
            }}
            onDone={() => {
              setShowConfirmDelete(false)
            }}
          />,
          document.getElementById('modal-root')!
        )}
    </React.Fragment>
  )
}
