import { StagedLicense, StagedUser } from '../dataTypes'
import { authenticatedDelete, authenticatedGet, authenticatedPost, defaultHttpErrorHandler } from '../lib/httpClient'
import { API_HOST } from '../lib/environment'

import { AddUserForLicenseDto, CreateLicenseDto, ProductWithRolesDto, Scope, TransitionToNewProduct } from './Dtos'

const GET_PRODUCT_WITH_ROLES_URL = `${API_HOST}/products`
const CREATE_LICENSE_FOR_USER = `${API_HOST}/license/{licenseId}/user`
const EXPIRE_LICENSE = `${API_HOST}/license/{licenseId}/expire`
const INVOICE_LICENSE = `${API_HOST}/license/{licenseId}/invoice`
const GET_LOCATION_CANDIDATES_FOR_SEARCH = `${API_HOST}/location/search`
const GET_LOCATION_CANDIDATES_FOR_IDS = `${API_HOST}/locations`
const TRANSITION_LICENSE_TO_NEW_PRODUCT = `${API_HOST}/license/{licenseId}/transition`
const CREATE_LICENSE_FOR_ACCOUNT_ENDPOINT = `${API_HOST}/account/{accountId}/licenses`
const CREATE_ALIAS_FOR_LICENSE = `${API_HOST}/alias/{alias}/licenses/{licenseId}`

async function createLicenses(accountId: string, stagedLicense: StagedLicense): Promise<void> {
  const url = `${CREATE_LICENSE_FOR_ACCOUNT_ENDPOINT}`.replace('{accountId}', accountId)
  const createLicenseRequest: CreateLicenseDto = {
    productName: stagedLicense.product,
    scopes: stagedLicense.scopes,
    from: new Date(stagedLicense.from),
    to: stagedLicense.to ? new Date(stagedLicense.to) : undefined,
    orderedBy: stagedLicense.orderedBy ? stagedLicense.orderedBy : undefined,
    ticketNo: stagedLicense.ticketNo ? stagedLicense.ticketNo : undefined,
    invoicedFrom: stagedLicense.invoicedFrom ? new Date(stagedLicense.invoicedFrom) : undefined
  }

  await authenticatedPost(url, createLicenseRequest).run().catch(defaultHttpErrorHandler)
}

function transitionLicenseToNewProduct(
  licenseId: string,
  targetProduct: string,
  orderedBy: string,
  ticketNo: string
): Promise<void> {
  const url = TRANSITION_LICENSE_TO_NEW_PRODUCT.replace('{licenseId}', licenseId)
  const transitionToNewProductRequest: TransitionToNewProduct = {
    orderedBy: orderedBy,
    ticketNo: ticketNo,
    targetProduct: targetProduct
  }
  return authenticatedPost(url, transitionToNewProductRequest).run()
}

function addUserToLicense(licenseId: string, stagedUser: StagedUser): Promise<void> {
  const url = CREATE_LICENSE_FOR_USER.replace('{licenseId}', licenseId)
  const userToActivate: AddUserForLicenseDto = {
    firstName: stagedUser.firstName,
    lastName: stagedUser.lastName,
    role: stagedUser.role,
    email: stagedUser.email
  }
  return authenticatedPost(url, userToActivate).run()
}

function expireLicenseAtDate(licenseId: string, date: Date): Promise<void> {
  const url = EXPIRE_LICENSE.replace('{licenseId}', licenseId)
  return authenticatedPost(url, {
    to: date
  })
    .run()
    .catch(defaultHttpErrorHandler)
}

function startInvoicingAtDate(licenseId: string, date: Date): Promise<void> {
  const url = INVOICE_LICENSE.replace('{licenseId}', licenseId)
  return authenticatedPost(url, {
    invoicedFrom: date
  }).run()
}

function fetchAvailableProductsWithRoles(): Promise<ProductWithRolesDto[]> {
  return authenticatedGet(`${GET_PRODUCT_WITH_ROLES_URL}`)
    .run()
    .then((resp: ProductWithRolesDto[]) => {
      return resp
    })
}

export type LocationCandidate = {
  locationId: string
  chain: string
  country: string
  storeName: string
  storeReference: string
  activeSerials: string[]
}

function searchForLocationCandidates(searchTerm: string): Promise<LocationCandidate[]> {
  return authenticatedGet(`${GET_LOCATION_CANDIDATES_FOR_SEARCH}?searchTerm=` + encodeURIComponent(searchTerm)).run()
}

function getLocationCandidatesForLocationIds(locationIds: string[]): Promise<LocationCandidate[]> {
  return authenticatedGet(
    `${GET_LOCATION_CANDIDATES_FOR_IDS}?locationIds=` + encodeURIComponent(locationIds.join(';'))
  ).run()
}

function disableLicense(licenseId: string): Promise<void> {
  return authenticatedDelete(`${API_HOST}/license/${licenseId}`).run()
}

function removeAlias(alias: string): Promise<void> {
  return authenticatedDelete(`${API_HOST}/alias/${alias}`).run()
}

function configurePolicyForLicense(licenseId: string, scopes?: Scope[]) {
  return authenticatedPost(`${API_HOST}/license/${licenseId}/scopes`, { scopes }).run()
}

function disableLicenseForUser(licenseForUserId: string): Promise<void> {
  return authenticatedDelete(`${API_HOST}/license-for-user/${licenseForUserId}`).run()
}

function updateUserRoleForLicense(licenseForUserId: string, role: string): Promise<void> {
  return authenticatedPost(`${API_HOST}/license-for-user/${licenseForUserId}/${role}`, {}).run()
}

function createAlias(alias: string, licenseId: string): Promise<void> {
  const url = CREATE_ALIAS_FOR_LICENSE.replace('{alias}', alias).replace('{licenseId}', licenseId)
  return authenticatedPost(url, {}).run()
}

function isAliasAvailable(aliasCandidate: string): Promise<boolean> {
  return authenticatedGet(`${API_HOST}/alias/${aliasCandidate}`, true)
    .run()
    .then(() => {
      // Alias exists
      return false
    })
    .catch(response => {
      if (response.status === 404) {
        // Alias does not exist, we should return true so that the alias will be created
        return true
      }

      return Promise.reject(
        new Error('Request failed with status=' + response.status + ', text=' + response.statusText)
      )
    })
}

export const LicenseService = {
  createLicenses,
  addUserToLicense,
  fetchAvailableProductsWithRoles,
  searchForLocationCandidates,
  getLocationCandidatesForLocationIds,
  disableLicense,
  disableLicenseForUser,
  createAlias,
  isAliasAvailable,
  transitionLicenseToNewProduct,
  configurePolicyForLicense,
  removeAlias,
  startInvoicingAtDate,
  expireLicenseAtDate,
  updateUserRoleForLicense
}
