import React, { ReactNode, useEffect, useState } from 'react'
import { LicenseService, LocationCandidate } from '../../services/LicenseService'
import { Scope } from '../../services/Dtos'
import { Search } from './Search'
import { DeleteIcon, ArrowDownIcon } from '../icons'
import { ReactComponent as CopyIcon } from '@tomra/design-system/src/config/icons/copy.svg'
import styled from 'styled-components'

const useLocationSearch = () => {
  const [searchHits, setSearchHits] = useState<LocationCandidate[]>([])
  const [isSearching, setIsSearching] = useState(false)

  const search = async (searchTerm: string): Promise<void> => {
    setIsSearching(true)

    // Simulate request time
    return LicenseService.searchForLocationCandidates(searchTerm).then(returnedCandidates => {
      setIsSearching(false)
      setSearchHits(returnedCandidates)
    })
  }

  const resetSearch = () => {
    setSearchHits([])
  }

  const searchError: string | undefined = ''

  return {
    searchHits,
    isSearching,
    search,
    resetSearch,
    searchError
  }
}

const ActiveInstallationsStyled = styled.div`
  overflow: hidden;
  position: absolute;
  z-index: 9999;
  background-color: white;
  top: 2em;
  left: 0;
  width: 100%;
  padding: var(--spacing-sm);
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-small);
  border: 1px solid #eee;
`

const ActiveInstallations = ({ activeSerials, children }: { activeSerials: string[]; children: ReactNode }) => {
  const [show, setShow] = useState(false)
  return (
    <React.Fragment>
      <div className="inline" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        {children}
      </div>
      {show ? (
        <ActiveInstallationsStyled>
          <span>Active Machines:</span>
          <ul>
            {activeSerials.map(installation => {
              return (
                <li key={installation} className="text-green font-bold">
                  {`${installation}`}
                </li>
              )
            })}
          </ul>
        </ActiveInstallationsStyled>
      ) : null}
    </React.Fragment>
  )
}

type Props = {
  onComplete: (scopes: Scope[]) => void
  onAdvanced: () => void
  initialLocationIds: string[]
  readOnly: boolean
  isSubmitting: boolean
}

export const SimpleScopesCreator = ({ onComplete, onAdvanced, initialLocationIds, readOnly, isSubmitting }: Props) => {
  const { searchHits, search, resetSearch, isSearching, searchError } = useLocationSearch()
  const [selectedLocations, setSelectedLocations] = useState<LocationCandidate[] | undefined>()

  useEffect(() => {
    if (initialLocationIds.length !== 0) {
      LicenseService.getLocationCandidatesForLocationIds(initialLocationIds).then(locationCandidates =>
        setSelectedLocations(locationCandidates)
      )
    } else {
      setSelectedLocations([])
    }
  }, [initialLocationIds])

  const searchHitsNotSelected = selectedLocations
    ? searchHits.filter(foundLocation => !selectedLocations.some(c => c.locationId === foundLocation.locationId))
    : []
  const title = readOnly ? 'Configured Access' : 'Configure Access to Stores'

  return (
    <div
      className="flex flex-col gap-md p-lg items-start"
      style={{ maxHeight: '90vh', minHeight: '40rem', width: '50rem' }}
    >
      <div className="w-full flex items-center justify-between pr-xl">
        <h1 className="text-xl">{title}</h1>

        <button className="btn" onClick={onAdvanced}>
          Switch to advanced
        </button>
      </div>

      {readOnly ? null : (
        <React.Fragment>
          <Search
            search={search}
            clearResults={resetSearch}
            isSearching={isSearching}
            label="Find Store"
            placeholder="Store ID, name or serial"
          />

          {searchHitsNotSelected.length > 0 ? (
            <React.Fragment>
              <div>[Store ID] Store Name (Country)</div>
              <div className="max-h-15 overflow-auto flex flex-col items-start">
                {searchHitsNotSelected.map(candidate => {
                  return (
                    <button
                      key={candidate.locationId}
                      className="relative block hover:bg-grey-light py-xs"
                      onClick={() => {
                        resetSearch()
                        setSelectedLocations(selectedLocations ? [...selectedLocations, candidate] : [candidate])
                      }}
                    >
                      [{candidate.storeReference}] {candidate.storeName} ({candidate.country})(
                      {candidate.activeSerials.length}{' '}
                      <ActiveInstallations activeSerials={candidate.activeSerials}>machines</ActiveInstallations>)
                    </button>
                  )
                })}
              </div>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      )}

      {selectedLocations === undefined ? (
        <div>
          <span>Selected Stores (Store ID, Chain, Country):</span>
          <div className="loadingSpinner" />
        </div>
      ) : (
        <div>
          <h3 className="text-md">Selected Stores (Store ID, Chain, Country):</h3>

          {selectedLocations.length === 0
            ? 'None'
            : selectedLocations
            ? selectedLocations.map(location => {
                return (
                  <div className="relative flex items-center" key={location.locationId}>
                    {readOnly ? null : (
                      <button
                        aria-label={`Delete ${location.storeName}`}
                        className="btn btn-icon"
                        onClick={() =>
                          setSelectedLocations(selectedLocations.filter(loc => loc.locationId !== location.locationId))
                        }
                      >
                        <DeleteIcon color="var(--colors-red)" />
                      </button>
                    )}

                    <span>{`${location.storeName} (${location.storeReference}, ${location.chain}, ${location.country})`}</span>
                    <ActiveInstallations activeSerials={location.activeSerials}>
                      <ArrowDownIcon />
                    </ActiveInstallations>
                    {location.activeSerials.length > 0 ? (
                      <button
                        className="btn btn-icon"
                        onClick={() => navigator.clipboard.writeText(location.activeSerials.toString())}
                      >
                        <CopyIcon className="inline-block" width="1rem" height="1rem" title="Copy serials" />
                      </button>
                    ) : null}
                  </div>
                )
              })
            : null}
        </div>
      )}

      {searchError ? <p className="text-red">{searchError}</p> : null}

      {readOnly ? null : (
        <button
          className="btn btn-success self-end"
          disabled={isSubmitting}
          onClick={() =>
            onComplete(
              selectedLocations?.map(location => ({
                include: [{ type: 'locationId', id: location.locationId }],
                exclude: []
              })) ?? []
            )
          }
        >
          Submit
        </button>
      )}
    </div>
  )
}
