import React from 'react'
import { PieChart } from 'react-minimal-pie-chart'

export type Statistics = {
  title: string
  value: number
  color: string
  textColor: string
}

type Props = {
  product: string
  dataSet: Statistics[]
}

function Legend({ title, color }: { title: string; color: string }) {
  return (
    <div>
      <div className="inline-block mr-xs" style={{ width: '1rem', height: '1rem', backgroundColor: color }} />
      {title}
    </div>
  )
}

export function StatisticsCard({ product, dataSet }: Props) {
  return (
    <div className="p-md border">
      <h3 className="text-lg m-sm mb-md text-center">{product}</h3>

      <div style={{ width: 240 }}>
        <PieChart
          viewBoxSize={[240, 240]}
          center={[120, 120]}
          radius={120}
          label={labelProps => {
            const { dataIndex, dataEntry, ...props } = labelProps
            return (
              <text {...props} key={dataIndex} fill={dataSet[dataIndex].textColor}>
                {dataSet[dataIndex].value}
              </text>
            )
          }}
          labelPosition={50}
          labelStyle={{
            fontFamily: 'sans-serif',
            fontSize: '14px'
          }}
          data={dataSet.map(({ title, value, color }) => {
            return {
              title,
              value,
              color
            }
          })}
        />
        <div className="mt-md text-xs">
          {dataSet.map(({ title, color }) => (
            <Legend key={title} {...{ title, color }} />
          ))}
        </div>
      </div>
    </div>
  )
}
