import React, { useState } from 'react'
import { userIsAdmin } from '../../lib/jwt'
import { Link, useLoaderData, useRouteLoaderData, useSearchParams } from 'react-router-dom'
import { AccountDto, BusinessUnit } from '../../services/Dtos'
import { SortableHeader, useTableSorting } from '../../components/TableColumnSort'
import { AccountRow } from './AccountRow'
import { TableFooter } from '../../components/TableFooter'
import { CardDialog } from '../../components/CardDialog'
import { AddAccountForm } from './AddAccountForm'

export const AccountsOverviewPage = () => {
  const businessUnits = useRouteLoaderData('withBusinessUnits') as BusinessUnit[]
  const [searchParams] = useSearchParams()
  const accounts = useLoaderData() as AccountDto[]
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPrPage, setRecordsPrPage] = useState(10)
  const [accountFilter, setAccountFilter] = useState('')
  const [showCreateAccountForm, setShowCreateAccountForm] = useState(false)

  const onlyInactive = searchParams.get('onlyInactive')

  const sorting = useTableSorting<AccountDto>(
    {
      'External ID': account => account.externalId,
      'Business Unit': account =>
        businessUnits.find(bu => bu.id === account.businessUnitId)?.name || 'Unknown ID: ' + account.businessUnitId,
      'Account Name': account => account.name,
      Description: account => account.description ?? '',
      Created: account => account.created,
      Creator: account => account.authorName
    },
    'Business Unit'
  )

  const startIndex = (currentPage - 1) * recordsPrPage

  const accountCandidates = sorting.sort(
    accounts.filter(account => {
      const filterTerm = accountFilter.toLocaleLowerCase().trim()
      const contents = `${account.externalId} ${account.name} ${account.description ?? ''} ${account.businessUnitId}`
      return contents.toLocaleLowerCase().includes(filterTerm)
    })
  )

  const accountsToRender = accountCandidates.slice(startIndex, startIndex + recordsPrPage)

  return (
    <div>
      <div className="flex items-end justify-between mb-md ml-md">
        <div>
          <h1 className="text-xl">Accounts {onlyInactive ? 'with no active installations' : ''}</h1>
        </div>
        <div>
          {userIsAdmin() && (
            <Link to="/accounts/deactivated" className="link mb-xs mr-md">
              Deactivated accounts
            </Link>
          )}
          {onlyInactive ? (
            <Link to="/accounts" className="link mb-xs mr-md">
              All accounts
            </Link>
          ) : (
            <Link to="/accounts?onlyInactive=true" className="link mb-xs mr-md">
              Accounts without installations
            </Link>
          )}
        </div>
      </div>

      <div style={{ position: 'relative' }}>
        <div className="card">
          <div className="p-md flex items-center justify-between">
            <div>
              <input
                type="text"
                className=" w-20"
                value={accountFilter}
                onChange={evt => {
                  setAccountFilter(evt.target.value)
                  setCurrentPage(1)
                }}
                placeholder="Filter"
              />
            </div>
            <div>
              <button className="btn btn-primary-dark" onClick={() => setShowCreateAccountForm(true)}>
                Create New Account
              </button>
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <SortableHeader name="Account Name" currentSort={sorting.currentSort} minWidth={'10em'} />
                <SortableHeader name="External ID" currentSort={sorting.currentSort} minWidth={'15em'} />
                <SortableHeader name="Description" currentSort={sorting.currentSort} minWidth={'28em'} />
                <SortableHeader name="Business Unit" currentSort={sorting.currentSort} minWidth={'13em'} />
                <SortableHeader name="Created" currentSort={sorting.currentSort} />
                <SortableHeader name="Creator" currentSort={sorting.currentSort} minWidth={'12em'} />
              </tr>
            </thead>
            <tbody>
              {accountsToRender.map(account => (
                <AccountRow account={account} businessUnits={businessUnits} key={account.id} />
              ))}
            </tbody>
          </table>

          <TableFooter
            currentPage={currentPage}
            numOfPages={Math.max(1, Math.ceil(accountCandidates.length / recordsPrPage))}
            numOfItems={accountCandidates.length}
            recordsPerPage={recordsPrPage}
            onPageUpdate={(newPage: number) => setCurrentPage(newPage)}
            onRecordsPerPageChange={(recordsPerPage: number) => {
              setRecordsPrPage(recordsPerPage)
              setCurrentPage(1)
            }}
          />

          {showCreateAccountForm && (
            <CardDialog onClose={() => setShowCreateAccountForm(false)}>
              <AddAccountForm businessUnits={businessUnits} />
            </CardDialog>
          )}
        </div>
      </div>
    </div>
  )
}
