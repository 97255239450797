import { authenticatedGet, authenticatedPost, defaultHttpErrorHandler } from '../lib/httpClient'
import { API_HOST } from '../lib/environment'
import { FoundUser } from '../lib/UserHooks'

const SEARCH_FOR_USER = `${API_HOST}/user/search?searchTerm={searchTerm}`
const CREATE_USER = `${API_HOST}/user`
export const GET_USER_DETAILS = `${API_HOST}/user/details/{userId}`
const SET_PASSWORD_FOR_USER = `${API_HOST}/user/set-password/{userId}`

async function searchForUsers(searchTerm: string): Promise<FoundUser[]> {
  const url = SEARCH_FOR_USER.replace('{searchTerm}', encodeURIComponent(searchTerm))

  // noinspection TypeScriptValidateJSTypes
  return authenticatedGet(url)
    .run()
    .then(parsedBody => parsedBody.users)
    .catch(() => undefined)
}

async function createUser(
  email: string,
  firstName: string,
  lastName: string,
  password?: string,
  redirect?: { uri: string; clientId: string },
  requireUpdatePassword?: boolean
): Promise<void> {
  return authenticatedPost(
    CREATE_USER,
    {
      email,
      firstName,
      lastName,
      password,
      requireUpdatePassword,
      redirect
    },
    true
  )
    .run()
    .catch(httpError => {
      if (httpError.status === 409) {
        throw new Error('User already exists, try searching for the e-mail again')
      }
      defaultHttpErrorHandler(httpError)
    })
}

async function setPasswordForUser(userId: string, password: string) {
  const url = SET_PASSWORD_FOR_USER.replace('{userId}', userId)
  return authenticatedPost(url, { password })
}

export const UserService = {
  createUser,
  setPasswordForUser,
  searchForUsers
}
