import React, { useState } from 'react'
import { useUserSearch, FoundUser } from '../../lib/UserHooks'
import { Link } from 'react-router-dom'
import { useTableSorting, SortableHeader } from '../../components/TableColumnSort'
import { TableFooter } from '../../components/TableFooter'

export const UsersOverviewPage = () => {
  const { searchResult, search } = useUserSearch()
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPrPage, setRecordsPrPage] = useState(10)

  const sorting = useTableSorting<FoundUser>(
    {
      Email: user => user.email,
      'Full Name': user => `${user.firstName} ${user.lastName}`,
      '# of Licenses': user => `${user.numOfLicenses}`
    },
    'Email'
  )

  const actualCandidates = searchResult.type === 'success' ? searchResult.value : []
  const isLoading = searchResult.type === 'loading' || searchResult.type === 'reloading'

  const sliceStart = (currentPage - 1) * recordsPrPage

  return (
    <div
      className="card"
      onKeyDown={e => {
        if (e.key === 'Enter') {
          search(searchTerm)
        }
      }}
    >
      <div className="flex items-center gap-sm p-md">
        <div>
          <input
            type="text"
            className="w-20"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search for users..."
          />
        </div>
        <button className="btn btn-primary-dark" disabled={isLoading} onClick={() => search(searchTerm)}>
          {isLoading ? <div className="loadingSpinner" /> : 'Search'}
        </button>
      </div>

      <table className="table">
        <thead>
          <tr>
            <SortableHeader name="Email" currentSort={sorting.currentSort} />
            <SortableHeader name="Full Name" currentSort={sorting.currentSort} />
            <SortableHeader name="# of Licenses" currentSort={sorting.currentSort} />
          </tr>
        </thead>
        <tbody>
          {sorting
            .sort(actualCandidates)
            .slice(sliceStart, sliceStart + recordsPrPage)
            .map(user => (
              <tr key={user.id}>
                <td>
                  <Link className="link" to={'/users/' + user.id}>
                    {user.email}
                  </Link>
                </td>
                <td>{`${user.firstName ?? ''} ${user.lastName ?? ''}`}</td>
                <td>{user.numOfLicenses}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <TableFooter
        currentPage={currentPage}
        numOfPages={Math.max(1, Math.ceil(actualCandidates.length / recordsPrPage))}
        numOfItems={actualCandidates.length}
        recordsPerPage={recordsPrPage}
        onPageUpdate={setCurrentPage}
        recordsPerPageOptions={[10, 20, 50, 100]}
        onRecordsPerPageChange={(recordsPerPage: number) => {
          setRecordsPrPage(recordsPerPage)
          setCurrentPage(1)
        }}
      />
    </div>
  )
}
